const config = {
    ImagesRootURL: "/images",
    TemplatesPerPage: 16,
    TemplatesImagesRootURL: "/images/templates",
    TemplatesScreenshotsRootURL: "/images/screenshots",
    TemplatesIconsURL: "/images/icons",
    TemplatesApplicationsURL: "/images/applications",
    ApiURL: "https://api.robomotion.io",
    AppURL: "https://explore.robomotion.io",
};

export default config;
