import {
    createStyles,
    Group,
    Container,
    Card,
    Center,
    Mark,
    Title,
    Text,
    Image,
    Badge,
} from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 40,
        backgroundColor: colors.PrimaryBackground,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    bottomTitle: {
        paddingTop: 20,
        textAlign: 'center',
        fontWeight: 800,
        fontSize: "20px",
        fontFamily: 'Inter'
    },

    listItem: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 20,
        maxWidth: "600px",
    },

    highlight: {
        fontWeight: 800,
    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    infoCard: {
        backgroundColor: colors.Transparent,
    },

    imageCard: {
        backgroundColor: colors.Transparent,
    },

    discord: {
        backgroundColor: colors.Transparent,
    },

    imageCardChild: {
        backgroundColor: colors.Transparent,
    },

    badge: {
        color: colors.Discord,
    }
}));

const Discord = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"100%"}>
            <div className={classes.hero}>
                <Center>
                    <Card className={classes.discord} radius="lg">
                        <Card className={classes.infoCard}>

                            <Title className={classes.title}>
                                Our  <b>Discord</b> 
                                <Text component="span"
                                    className={classes.highlight}
                                    inherit
                                    variant="gradient"
                                    gradient={{ from: colors.Secondary, to: colors.Secondary }}
                                >
                                {' '}<b>Community</b></Text>{' '}is Growing!
                                
                            </Title>
                            <Container p={0}>
                                <Center>
                                    <Text className={classes.subTitle}>
                                        Ask, Collaborate, Enroll Job Posts, Socialize.
                                    </Text>
                                </Center>
                            </Container>

                            <Container p={0} pb={20}>
                                <Center>
                                    <Text className={classes.description}>
                                        Join our community of <b>4500+</b> members. Gain access to our supportive and responsive community, along with our dedicated team, who are always available to provide prompt assistance whenever you need it.
                                    </Text>
                                </Center>
                            </Container>
                            <Container className={classes.wrapper} size={"700px"} pt={25}>
                                <Group pt={0} position={"center"}>
                                    <Badge variant="outline" color={"white"} size="xl" radius="md" className={classes.badge}>
                                        Share Your Experiences
                                    </Badge>
                                    <Badge variant="outline" color={"white"} size="xl" radius="md" className={classes.badge}>
                                        Meet with the Team
                                    </Badge>
                                    <Badge variant="outline" color={"white"} size="xl" radius="md" className={classes.badge}>
                                        Join Challenges
                                    </Badge>
                                </Group>
                            </Container>
                            <Container className={classes.wrapper} size={"200px"} pt={50}>
                                <a href="https://community.robomotion.io">
                                <Image src="/images/discord_trans.png" ></Image>
                                </a>
                            </Container>
                        </Card>
                    </Card>
                </Center>
            </div>
        </Container>

    );
}

export default Discord;
