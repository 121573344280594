const colors = {
    Primary: "#1d3557",
    Secondary: "#ff4f00",
    Tertiary: "#457b9d",
    Alternate: "#f1faee",

    PrimaryText: "$14181b",
    SecondaryText: "#d1dcde",
    WhiteText: "white",
    Transparent: "#FFFFFF00",

    PrimaryBackground: "#f1f4f8",
    SecondaryBackground: "#ffffff",
    TertiaryBackground: "#f6f5f4",

    Accent1: "#577590",
    Accent2: "#f94144",
    Accent3: "#a8dadc",
    Accent4: "#ccffff",

    Success: "#2a9d8f",
    Error: "#f94144",
    Warning: "#e9c46a",
    Info: "#ffffff",

    PrussianBlue: "#264653",
    Claret: "#2a9d8f",
    Camboge: "#e9c46a",
    Timberwolf: "#f4a261",
    LitOrange: "#e76f51",
    RichBlack: "#141313",
    Discord: "#325170" 

};

export default colors;

