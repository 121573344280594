import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import frontmatter from 'frontmatter';
import Details from './Details';

const Template = () => {
    const { slug } = useParams();
    const [template, setTemplate] = useState('');

    useEffect(() => {
        import(`./templates/${slug}.md`).then(res => {
            fetch(res.default).then(console.log(res.default))
            .then(res => res.text())
            .then(res => setTemplate(res));
        })
        .catch(err => console.log(err));
    });


    const mrkdwn = frontmatter(template);
    const content = mrkdwn.content;
    const data = mrkdwn.data;

    const title = data && data.title ? data.title : "Robomotion RPA";
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>   
            <Details data={data} content={content} />
        </>
    );
}

export default Template;