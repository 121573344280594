import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Users from '../shared/Users';
import Discord from '../shared/Discord';
import templates from './data'
import { Discover } from './Discover';

const Latest = () => {

    const title = "Latest Templates - Robomotion RPA";

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>   
            <Hero />
            <Discover data={templates} title="Latest Templates" latest={true} />
            <Users />
            <Discord />
        </>
    );
}

export default Latest;