import {
    createStyles,
    Title,
    Text,
    Container,
    Group,
    Flex,
    Pagination,
    Button,
    UnstyledButton,
    Card,
    ThemeIcon,
    SimpleGrid,
    Divider,
    Center,
    Select,

} from '@mantine/core';

import { useEffect, useState } from 'react';
import config from '../../const/config';
import Categories, { goTo } from '../shared/Categories';
import TemplateCard from '../shared/Card';

import { IconBrandDiscord } from "@tabler/icons"
import ApplicationIcon from "../shared/Applications"
import axios from 'axios';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 120,
        paddingBottom: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingTop: 100,
        paddingBottom: 100,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 28,
        fontFamily: 'Barlow',
        
    },


    highlight: {

    },

    gridHeader: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 20,
        fontSize: theme.fontSizes.xl,
    },

    mainView: {
        justifyContent: "flex-start",
        alignContent: "flex-start",
        flexDirection: "row",
        [theme.fn.smallerThan('sm')]: {
            flexWrap: "wrap",
        },
        paddingBottom: "100px",
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    divider: {

    },

    appItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: 90,
        width: 150,
        backgroundColor: colors.SecondaryBackground,
        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: `${theme.shadows.md} !important`,
            transform: 'scale(1.05)',
        },
    },
}));


const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
;


const getApplications = (templates) => {
    return templates.
        map(template => template.applications).
        flat(1).
        filter((template, i, arr) => arr.indexOf(template) == i).
        sort();
}

const getCategories = (templates) => {
    return templates.
        map(template => template.category).
        filter((template, i, arr) => arr.indexOf(template) == i).
        sort();
}


const onPageChanged = ({ state, setState, page }) => {
    setState({ ...state, page });
    const topElement = document.getElementById('discover_templates');
    window.scrollTo({ top: topElement?.offsetTop ?? 0, behavior: 'smooth' });
}

const groupBy = (arr, key) => {
    return arr.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const getLatestTemplates = (templates) => {
    const groups = groupBy(templates, 'created_at');
    const key = Object.keys(groups).sort().at(-1);
    return groups[key];
}

export async function listTemplates(templates) {
    const url = config.ApiURL;

    const tempMap = Object.fromEntries(
        templates.map(t => [t.slug.replaceAll('-', '_'), t])
    );

    try {
        const resp = await axios.get(`${url}/v1/templates.list`, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!resp.data.ok) {
            return;
        }

        resp.data.templates.forEach(t => {
            if (t.id in tempMap) {
                tempMap[t.id].star = t.star
                tempMap[t.id].stars = t.stars
                tempMap[t.id].imports = t.imports
            }
        })

        return tempMap;

    } catch (err) {
        console.error(err);
    }

    return null;
}

export function Discover({ title = 'All Templates', category = '', application = '', data = [], latest = false, featured = false }) {

    const { classes } = useStyles();
    const search = '';
    const allTemplates = data;

    const initialState = () => {
        const filtered = data.filter((tmp) => {
            if (category) {
                const slug = tmp.category.toLowerCase().replace(" ", "-");
                return category === slug;
            }
            if (application) {
                var hasApp = false;
                tmp.applications.forEach((app) => {
                    const slug = app.toLowerCase().replace(" ", "-").replace("_", "-");
                    if (application == slug) hasApp = true;
                })
                return hasApp;
            }
            return true;
        });
        return {
            page: 1,
            applications: getApplications(data),
            categories: getCategories(data),
            templates: filtered.sort((a, b) => toString(b.updated_at).localeCompare(toString(a.updated_at))),
            search: '',
            level: '0',
        }
    };

    const [state, setState] = useState(initialState);
    const _templates = latest ? getLatestTemplates(state.templates) : state.templates;

    const doFilter = (templates) => {
        return templates.filter(t => {
            const search = state.search.toLowerCase();
            return (!Boolean(state.search) || (t.name.toLowerCase().includes(search) || t.description.toLowerCase().includes(search))) &&
                (!featured || t.featured) &&
                (state.level === '0' || t.level === parseInt(state.level));
        });
    }

    const templates = doFilter(_templates);

    const page = templates.slice((state.page - 1) * config.TemplatesPerPage, state.page * config.TemplatesPerPage);

    return (

        <Container className={classes.wrapper} size={1400}>
            <Flex className={classes.mainView}>
                <Categories templates={state.templates} applications={state.applications}
                    categories={state.categories} application={application} category={category} search={state.search}
                    onSearch={(search) => setState({ ...state, search })} />
                <Container mx={36}>
                    <Container>
                        <Group className={classes.gridHeader} position="apart">
                            <Group position="left">
                                <Text size="xl" weight={700} mx="-10px" variant="gradient" gradient={{ from: colors.Secondary, to: colors.Secondary }}>
                                    {`${capitalize(title)} `}
                                </Text>
                                <Text size="xl" weight={400} color="dimmed" mx="-4px" px={1}>({templates.length})</Text>
                            </Group>

                            <Group position='apart'>
                                <Select
                                    placeholder="Filter"
                                    size="md"
                                    defaultValue={state.level}
                                    onChange={(value) => setState({ ...state, level: value })}
                                    data={[
                                        { value: '1', label: 'Beginner' },
                                        { value: '2', label: 'Intermediate' },
                                        { value: '3', label: 'Advanced' },
                                        { value: '0', label: 'All' },
                                    ]}
                                />
                            </Group>
                        </Group>
                    </Container>
                    <SimpleGrid id='discover_templates' cols={3}
                        breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 1 }]}>
                        {
                            page.length > 0 ?
                                page.map(template => {
                                    const description = template.description.length > 100 ?
                                        template.description.substring(0, 100) + '...' :
                                        template.description;
                                    return (
                                        <TemplateCard
                                            key={template.slug}
                                            level={template.level}
                                            applications={template.applications}
                                            name={template.name}
                                            description={description}
                                            icon={template.icon}
                                            iconColor={template.color}
                                            slug={template.slug}
                                            featured={template.featured}
                                            star={Boolean(template.star)}
                                            stars={template.stars ?? 0}
                                            imports={template.imports ?? 0}
                                        />
                                    );
                                }) :
                                <>
                                    <div style={{ minWidth: 320 }} className="text-md font-semibold leading-normal header ml-2 my-auto mt-1">No templates found.</div>
                                </>
                        }
                    </SimpleGrid>
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
                        <Pagination page={state.page}
                            onChange={(page) => onPageChanged({ state, setState, page })}
                            total={Math.ceil(templates.length / 15)} />
                    </div>

                </Container>
            </Flex>
            <Divider variant="dotted" className={classes.divider} />
            <div className={classes.hero} pt={50}>
                <Title className={classes.title}>
                        Need <Text component="span"
                            className={classes.highlight}
                            inherit
                            variant="gradient"
                            gradient={{ from: colors.Secondary, to: colors.Secondary }}
                        >
                        {' '}<b>Applications</b></Text>
                    </Title>

                    <Container p={5} pb={100} size={1200}>
                        <Center>
                            <Text className={classes.description}>
                                Check our <b>Application</b> <b>Templates</b>.
                            </Text>
                        </Center>
                    </Container>
                <Center>
                    <SimpleGrid cols={7} breakpoints={[{ maxWidth: 'sm', cols: 3 }, { maxWidth: 'md', cols: 5 }]}>
                        {state.applications.map(app => {
                            const appIcon = ApplicationIcon(app)
                            if (appIcon.type === "tabler") {
                                return (
                                    <UnstyledButton key={app.replace("_", " ")} className={classes.appItem}
                                        onClick={() => goTo(`/applications/${app.toLowerCase().replace(" ", "-").replace("_", "-")}/`)}>
                                        <ThemeIcon color={appIcon.color} radius={"42px"} size={"42px"} px={0} mx={0}>
                                            <appIcon.icon size={30} stroke={1} />
                                        </ThemeIcon>
                                        <Text size="md" mt={7}>
                                            {app.replace("_", " ")}
                                        </Text>
                                    </UnstyledButton>
                                );
                            } else {
                                return (
                                    <UnstyledButton key={app.replace("_", " ")} className={classes.appItem}
                                        onClick={() => goTo(`/applications/${app.toLowerCase().replace(" ", "-").replace("_", "-")}/`)}>
                                        <ThemeIcon color={appIcon.color} radius={"42px"} size={"42px"} px={0} mx={0}>
                                            {appIcon.icon}
                                        </ThemeIcon>
                                        <Text size="md" mt={7}>
                                            {app.replace("_", " ")}
                                        </Text>
                                    </UnstyledButton>
                                );
                            }
                        })}
                    </SimpleGrid>
                </Center>
            </div>
            <Divider my="xl" variant="dotted" className={classes.divider} />

        </Container>
    );
}