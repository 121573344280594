import {
	createStyles,
	Text,
	Button,
	Badge,
	Group,
	ActionIcon,
	Card,
	ThemeIcon,
	Rating,
} from '@mantine/core';
import ApplicationIcon from '../shared/Applications'

import {
	IconUpload,
} from '@tabler/icons';

const useStyles = createStyles((theme) => ({

	card: {
		transition: 'transform 150ms ease, box-shadow 150ms ease',

		'&:hover': {
			transform: 'scale(1.01)',
			boxShadow: theme.shadows.md,
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundSize: 'cover',
		backgroundPosition: 'center',

		'&[data-featured="true"]': {
			boxShadow: theme.shadows.xl,

			'&.mantine-Paper-root': {
				border: '.15em solid transparent',
				borderRadius: 8,
				backgroundOrigin: 'border-box',
				backgroundClip: 'padding-box, border-box',

				// the first gradient is for background
				// the second gradient is for border
				backgroundImage: 'linear-gradient(53deg, #FFF9DB 20%, #FFF4E6 50%, #FFF9DB), linear-gradient(53deg, #FFF4E6 30%, #FFF4E6 );',
			}
		},
	},

	description: {
		marginTop: "-10px !important",
	},

	title: {
		fontFamily: 'Barlow, sans-serif',
		fontWeight: '700',
		color: "#325170F0",
		paddingTop: "0px !important",
		marginTop: "-10px !important",
	},

	body: {
		minHeight: "80px"
	},


	hiddenMobile: {
		[theme.fn.smallerThan('md')]: {
			display: 'none',
		},
	},

	hiddenDesktop: {
		[theme.fn.largerThan('md')]: {
			display: 'none',
		},
	},

	footer: {
		padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
		marginTop: theme.spacing.md,
		borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
			}`,
	},
}));


function GetFeatured(isFeatured) {
	if (isFeatured) {
		return (
			<Badge color="yellow" variant="outline">Featured</Badge>
		);
	}
	else {
		return (
			<></>
		);
	}

}


function GetBadge(level) {
	if (level === 1) {
		return (
			<Badge variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Beginner</Badge>
		);
	}
	else if (level === 2) {
		return (
			<Badge variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}>Intermediate</Badge>
		);
	}
	else if (level === 3) {
		return (
			<Badge variant="gradient" gradient={{ from: 'orange', to: 'red' }}>Advanced</Badge>
		);
	}
}

const TemplateCard = ({ icon, level, applications, iconColor, name, description, slug, featured = false, stars = 0, imports = 0, star = false }) => {

	const { classes, theme } = useStyles();

	return (
		<Card withBorder radius="md" p="md" component="a" href={`/templates/${slug}`} className={classes.card} >

			<Group position="apart">
				<Group spacing="xs">
					{
						applications.map((application) => {
							const appIcon = ApplicationIcon(application)
							if (appIcon.type === "tabler") {
								return (
									<ThemeIcon key={appIcon.label} color={appIcon.color} radius={"42px"} size={"42px"} /*variant={"light"}*/ px={0} mx={0}>
										<appIcon.icon size={30} stroke={1} />
									</ThemeIcon>
								)
							} else {
								return (
									<ThemeIcon key={appIcon.label} color={appIcon.color} radius={"42px"} size={"42px"} /*variant={"light"}*/ px={0} mx={0}>
										{appIcon.icon}
									</ThemeIcon>
								);
							} 
						})
					}
				</Group>
				{GetBadge(level)}
			</Group>
			<Group mt="xl">
				{GetFeatured(featured)}
				<Text size="xl" className={classes.title}>
					{name}
				</Text>
				<Text size="md" color="dimmed" mt={0} className={classes.description}>
					{description}
				</Text>
			</Group>
			<Card.Section className={classes.footer}>
				<Group position="apart">
					<Button variant="light" size="xs">
						Use
					</Button>
					<Group spacing={2}>
						<ActionIcon>
							<Rating value={star ? 1 : 0} size="md" count={1} />
						</ActionIcon>
						<Text size="xs" color="dimmed">
							{stars}
						</Text>
						<ActionIcon>
							<IconUpload size={18} color={theme.colors.gray[6]} stroke={1.5} />
						</ActionIcon>
						<Text size="xs" color="dimmed">
							{imports}
						</Text>
					</Group>

				</Group>
			</Card.Section>
		</Card>
	);
}

export default TemplateCard;
