const templates = [
 {
  "name": "Track Projects on Airtable",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Shows create, read, update, delete and list record operations in Simple Project Tracker template of Airtable",
  "slug": "airtable-simple-project-tracker",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Airtable",
   "Javascript"
  ],
  "tags": [
   "airtable",
   "record"
  ],
  "icon": "airtable_simple_project_tracker_icon.png",
  "link": "DVdpPPGJ8PD5EqrG2cgXF7",
  "price": "Free",
  "cover": "airtable_simple_project_tracker_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Amazon S3 Quick Start",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Shows upload, get, download and delete object operations in S3 bucket",
  "slug": "amazon-s3-quick-start",
  "category": "Files",
  "featured": false,
  "applications": [
   "Amazon_S3",
   "Javascript"
  ],
  "tags": [
   "s3",
   "storage",
   "object",
   "cloud",
   "bucket"
  ],
  "icon": "amazon_s3_quick_start_icon.png",
  "link": "Ea4wKyrvnoGgj29KWaTXdu",
  "price": "Free",
  "cover": "amazon_s3_quick_start_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Scrape Search Result on Amazon",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Scrapes product data from Amazon search results. Results are written to an embedded SQLite database file named products.db which can be found on the desktop after the robot execution.",
  "slug": "amazon-serp-scraping",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "SQLite",
   "Javascript"
  ],
  "tags": [
   "scraping",
   "sqlite",
   "amazon"
  ],
  "icon": "amazon_serp_scraping_icon.png",
  "link": "DtBoGbhVHs9uy4DnB5ryxC",
  "price": "Free",
  "cover": "amazon_serp_scraping_screenshot_1.png",
  "author": "Robomotion"
 },
 {
  "name": "Check API Health",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Monitors the operational status of your microservices",
  "slug": "api-health-check",
  "category": "Monitoring",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "api",
   "monitoring"
  ],
  "icon": "api_health_check_icon.png",
  "link": "WwdJXn6MbuFjfccPrLfoRU",
  "price": "Free",
  "cover": "api_health_check_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Backup To S3 Bucket",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Creates a zip file and uploads to S3 bucket",
  "slug": "backup-to-s3-bucket",
  "category": "Files",
  "featured": false,
  "applications": [
   "Javascript",
   "Amazon_S3"
  ],
  "tags": [
   "s3 storage",
   "compress",
   "file",
   "backup",
   "cloud"
  ],
  "icon": "backup_to_s3_bucket_icon.png",
  "link": "ForeXY3npYZDC4syiAN2xq",
  "price": "Free",
  "cover": "backup_to_s3_bucket_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Pair Prices on Binance",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Collects all pair prices from Binance API. The price results save as an excel file.",
  "slug": "binance-collect-pair-prices",
  "category": "Crypto",
  "featured": false,
  "applications": [
   "Binance"
  ],
  "tags": [
   "crypto",
   "price",
   "excel",
   "pair"
  ],
  "icon": "binance_icon.png",
  "link": "2MZydmChyjbYcTrJvqGBRN",
  "price": "Free",
  "cover": "binance_collect_pair_prices_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Track Pair Price on Binance",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Receives pair from the flow. The pair get the price on Binance API. The found price is saved as an excel file.",
  "slug": "binance-track-pair-prices",
  "category": "Crypto",
  "featured": false,
  "applications": [
   "Binance"
  ],
  "tags": [
   "crypto",
   "price",
   "pair"
  ],
  "icon": "binance_icon.png",
  "link": "3ufqWsmBRW4AMncDkBh6uH",
  "price": "Free",
  "cover": "binance_price_tracker_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Celebrate Birthday on Slack",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Sends birthday messages to Slack Channel",
  "slug": "birthday-celebrator",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Slack",
   "Excel"
  ],
  "tags": [
   "chat",
   "messaging"
  ],
  "icon": "birthday_celebrator_icon.png",
  "link": "2Xy7BUR65M8wfg8uFhaz6c",
  "price": "Free",
  "cover": "birthday_celebrator_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Calculate Your BMI",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Calculates Body Mass Index (BMI)",
  "slug": "bmi-calculator",
  "category": "Other",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "body",
   "mass",
   "bmi",
   "calculate"
  ],
  "icon": "bmi_calculator_icon.png",
  "link": "NS7tgeMKNpANNMPDLoAUtL",
  "price": "Free",
  "cover": "bmi_calculator_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Calculate Basic Math with Calculator",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Performs arithmetic operations in the excel file",
  "slug": "calculator",
  "category": "Other",
  "featured": false,
  "applications": [
   "Javascript",
   "Excel"
  ],
  "tags": [
   "excel",
   "calculate"
  ],
  "icon": "calculator_icon.png",
  "link": "DYaNtaLh1UQeko431PDHcz",
  "price": "Free",
  "cover": "calculator_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Check Image Safety",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Checks an image's safety via Google Vision",
  "slug": "check-image-safety",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Google_Vision"
  ],
  "tags": [
   "image",
   "safety",
   "ai",
   "google"
  ],
  "icon": "check_image_safety_icon.png",
  "link": "Lpf1DCCcjyqwN44H3pJ37k",
  "price": "Free",
  "cover": "check_image_safety_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Click Image on Desktop",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Searches a predefined image in the screen. If finds an image with a confidence level which is larger than specified confidence value, clicks to it.",
  "slug": "click-image-in-screen",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "image",
   "search",
   "click",
   "vision"
  ],
  "icon": "click_image_in_screen_icon.png",
  "link": "QLYY2mXibH3BJxwdmvfwXS",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Click To Specific Point on Desktop",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Clicks to specified point in the screen with mouse.",
  "slug": "click-to-specific-point",
  "category": "Other",
  "featured": true,
  "applications": [
   "Other"
  ],
  "tags": [
   "mouse",
   "click",
   "system"
  ],
  "icon": "click_to_specific_point_icon.png",
  "link": "66u2Hoa6cTukQme7qkV481",
  "price": "Free",
  "cover": "click_to_specific_point_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Email Addresses from Website",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 3,
  "description": "Extract e-mail addresses from the given website and save to an excel file.",
  "slug": "collect-email-addresses-from-website",
  "category": "Scraping",
  "featured": true,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "mail",
   "http",
   "request",
   "regexp"
  ],
  "icon": "website_icon.png",
  "link": "E6DCLPU7DZkUKboeXycch7",
  "price": "Free",
  "cover": "collect_mail_from_website_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Jobs From Glassdoor",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 3,
  "description": "Collects job posts from Glassdoor API and saves to an excel file.",
  "slug": "collect-jobs-from-glassdoor",
  "category": "Job Scraping",
  "featured": true,
  "applications": [
   "Glassdoor",
   "Javascript"
  ],
  "tags": [
   "glassdoor",
   "job",
   "http",
   "request",
   "scrape"
  ],
  "icon": "glassdoor_icon.png",
  "link": "Ko7q67mS7oFYUMpSLWTeNW",
  "price": "Free",
  "cover": "collect_jobs_from_glassdoor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Jobs From Indeed",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 3,
  "description": "Collects jobs from Indeed and save to excel file.",
  "slug": "collect-jobs-from-indeed",
  "category": "Job Scraping",
  "featured": true,
  "applications": [
   "Indeed",
   "Javascript"
  ],
  "tags": [
   "indeed",
   "job",
   "http",
   "request",
   "scrape"
  ],
  "icon": "indeed_icon.png",
  "link": "X75aa21FYLBfHMqDZQARtx",
  "price": "Free",
  "cover": "collect_jobs_from_indeed_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Jobs From Linkedin",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 3,
  "description": "Collects jobs from Linkedin api and save to excel file.",
  "slug": "collect-jobs-from-linkedin",
  "category": "Job Scraping",
  "featured": true,
  "applications": [
   "Linkedin",
   "Javascript"
  ],
  "tags": [
   "linkedin",
   "job",
   "http",
   "request",
   "scrape"
  ],
  "icon": "linkedin_icon.png",
  "link": "CnjtMkWU4RAhuS7dMropE9",
  "price": "Free",
  "cover": "collect_jobs_from_linkedin_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Jobs From Monster",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 2,
  "description": "Collects job posts from Monster and saves to excel file.",
  "slug": "collect-jobs-from-monster",
  "category": "Job Scraping",
  "featured": true,
  "applications": [
   "Monster",
   "Javascript"
  ],
  "tags": [
   "monster",
   "job",
   "http",
   "request",
   "scrape"
  ],
  "icon": "monster_icon.png",
  "link": "YaVmbQ84yhaUAZRHFrtu2D",
  "price": "Free",
  "cover": "collect_jobs_from_monster_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Jobs From Workable",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 2,
  "description": "Collects job posts from Workable using api calls and save to excel file.",
  "slug": "collect-jobs-from-workable",
  "category": "Job Scraping",
  "featured": true,
  "applications": [
   "Workable",
   "Javascript"
  ],
  "tags": [
   "workable",
   "job",
   "http",
   "request",
   "scrape",
   "api"
  ],
  "icon": "workable_icon.png",
  "link": "BNfDb5adXSqgGX2roDTUHH",
  "price": "Free",
  "cover": "collect_jobs_from_workable_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Check Content Changes on Website",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Checks specific field in given website url and notifies if content has changed.",
  "slug": "content-checker",
  "category": "Monitoring",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "monitoring"
  ],
  "icon": "content_checker_icon.png",
  "link": "ExDmeLEF65kzZp2zr89oZG",
  "price": "Free",
  "cover": "content_checker_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Simple Countdown",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Counts down for a specified date starting from the current time",
  "slug": "countdown",
  "category": "Other",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "countdown",
   "time",
   "date"
  ],
  "icon": "countdown_icon.png",
  "link": "39brwbtnL17n3NAE7md8Ro",
  "price": "Free",
  "cover": "countdown_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Convert CSV to Google Sheets",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "This template uses CSV and Google Sheets nodes for read range from a CSV file and write it to Google Sheet file.",
  "slug": "csv-to-google-sheets",
  "category": "Files",
  "featured": false,
  "applications": [
   "Google_Sheets"
  ],
  "tags": [
   "csv",
   "google",
   "sheets"
  ],
  "icon": "csv_to_google_sheets_icon.png",
  "link": "YCjZTrj9u4Ejz79wN9H2Jr",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Calculate Date Difference in Given Dates",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Calculates the difference between now and a specific date",
  "slug": "date-difference-calculator",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "date",
   "difference",
   "calculate",
   "timespan"
  ],
  "icon": "date_difference_calculator_icon.png",
  "link": "5xWU1s5ky5HtBdSxRewuiD",
  "price": "Free",
  "cover": "date_difference_calculator_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Recive \u0026 Reply Messages on Discord",
  "created_at": "2022-10-11T16:46:59",
  "updated_at": "2022-10-11T16:46:59",
  "level": 3,
  "description": "This template receives message from channel and replies it with received content.",
  "slug": "discord-bot-chat",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Discord"
  ],
  "tags": [
   "discord",
   "bot",
   "chat"
  ],
  "icon": "discord_bot_chat_icon.png",
  "link": "TmKVnZxj2jQ9xgrfd52WpJ",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Collect Feedbacks on Discord",
  "created_at": "2022-10-11T16:46:59",
  "updated_at": "2022-10-11T16:46:59",
  "level": 2,
  "description": "This template collects feedbacks on discord with modals.",
  "slug": "discord-feedback-receiver",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Discord"
  ],
  "tags": [
   "discord",
   "bot",
   "feedback"
  ],
  "icon": "discord_feedback_receiver_icon.png",
  "link": "3165damy2DZNERwnXP418r",
  "price": "Free",
  "cover": "discord_feedback_receiver_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Send Notification on Discord",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Send Discord messages with embeds using webhooks.",
  "slug": "discord-notification",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Discord"
  ],
  "tags": [
   "discord",
   "notification",
   "api"
  ],
  "icon": "discord_notification_icon.png",
  "link": "AXHrJ4gAJXB3dq8tzAGsUb",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Send Prompt to OpenAI using Discord Messaging",
  "created_at": "2022-10-11T16:46:59",
  "updated_at": "2022-10-11T16:46:59",
  "level": 3,
  "description": "Takes prompt from Discord user sends it to OpenAI then replies message with response.",
  "slug": "discord-openai-bot",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Discord",
   "OpenAI"
  ],
  "tags": [
   "discord",
   "bot",
   "openai",
   "ai"
  ],
  "icon": "discord_openai_bot_icon.png",
  "link": "FRFH7NBWm4gYF5mSVNmJHF",
  "price": "Free",
  "cover": "discord_openai_bot_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Get Pair Prices with Discord",
  "created_at": "2022-11-21T15:46:59",
  "updated_at": "2022-11-21T15:46:59",
  "level": 2,
  "description": "This template listens to a Discord channel according to filters and replies messages with the price of given pair.",
  "slug": "discord-price-bot",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Discord",
   "Binance"
  ],
  "tags": [
   "discord",
   "bot",
   "crypto",
   "message"
  ],
  "icon": "discord_price_bot_icon.png",
  "link": "GiQuq5dcfagAKiamuHujeJ",
  "price": "Free",
  "cover": "discord_price_bot_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Set Reminder on Discord",
  "created_at": "2022-10-11T16:46:59",
  "updated_at": "2022-10-11T16:46:59",
  "level": 3,
  "description": "Send reminder message to a user with given interval.",
  "slug": "discord-reminder-bot",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Discord"
  ],
  "tags": [
   "discord",
   "bot",
   "message"
  ],
  "icon": "discord_reminder_bot_icon.png",
  "link": "6hV25zuKqhVK6RjUJErKiw",
  "price": "Free",
  "cover": "discord_reminder_bot_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Serve Dollar/Euro Parity As Service",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Returns dolar - euro parity for API request",
  "slug": "dollar-euro-parity-as-service",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "rest",
   "api"
  ],
  "icon": "dollar_euro_parity_as_service_icon.png",
  "link": "KQx88LP6cU6JF9STkTPnYG",
  "price": "Free",
  "cover": "dollar_euro_parity_as_service_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Download File From SSH Server",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Downloads file from SSH server to local computer",
  "slug": "download-file-from-ssh-server",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "ssh",
   "remote",
   "download"
  ],
  "icon": "download_file_from_ssh_server_icon.png",
  "link": "Pp3Pz717jvceQVv84GPiLn",
  "price": "Free",
  "cover": "download_file_from_ssh_server_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Download File From Web",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Receives File URL address from the flow. goes to this URL address in the browser, downloads to the specific file directory.",
  "slug": "download-file-from-web",
  "category": "Files",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "download",
   "file",
   "web"
  ],
  "icon": "download_file_from_web_icon.png",
  "link": "E7rjLMno5ZcCAt3VJnBC6D",
  "price": "Free",
  "cover": "download_file_from_web_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Dropbox Quick Start",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Shows how to make some Dropbox operations",
  "slug": "dropbox-quick-start",
  "category": "Files",
  "featured": false,
  "applications": [
   "Dropbox"
  ],
  "tags": [
   "dropbox",
   "storage",
   "file",
   "cloud"
  ],
  "icon": "dropbox_quick_start_icon.png",
  "link": "PHQN8uw7qbWKSH5VkY2f5G",
  "price": "Free",
  "cover": "dropbox_quick_start_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Remove Duplicate Files in Given Directory",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Removes duplicated files in a given directory in your file system",
  "slug": "duplicate-file-remover",
  "category": "Files",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "javascript",
   "duplicate",
   "file",
   "remover",
   "directory",
   "cryptography"
  ],
  "icon": "duplicate_file_remover_icon.png",
  "link": "AXy7pzbiYqbmZwMWjwg2fe",
  "price": "Free",
  "cover": "duplicate_file_remover_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Remove Duplicate Rows in Excel",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Finds and removes duplicate rows in excel file",
  "slug": "duplicate-rows-remover",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Javascript",
   "Excel"
  ],
  "tags": [
   "excel",
   "remover"
  ],
  "icon": "duplicate_rows_remover_icon.png",
  "link": "8JJZhh8pKAUzJRzFqaJPpe",
  "price": "Free",
  "cover": "duplicate_rows_remover_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Copy Data From Sheet to Sheet on Excel",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Copies and pastes data between sheets",
  "slug": "excel-copy-paste",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Javascript",
   "Excel"
  ],
  "tags": [
   "excel",
   "clipboard"
  ],
  "icon": "excel_copy_paste_icon.png",
  "link": "RxVF8C58UX7EiSpQozTVJN",
  "price": "Free",
  "cover": "excel_copy_paste_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Move Data from Excel to Word Document",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Reads range from excel file and writes to a newly created word document",
  "slug": "excel-range-to-word-table",
  "category": "Files",
  "featured": false,
  "applications": [
   "Excel",
   "Word",
   "Javascript"
  ],
  "tags": [
   "microsoft",
   "excel",
   "word",
   "document"
  ],
  "icon": "excel_range_to_word_table_icon.png",
  "link": "Q1HEBngNCZ18m7GCjwSd91",
  "price": "Free",
  "cover": "excel_range_to_word_table_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Convert Excel Document to CSV File",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Converts an excel file to a csv file in your file system",
  "slug": "excel-to-csv",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Excel"
  ],
  "tags": [
   "excel",
   "csv",
   "conversion"
  ],
  "icon": "excel_to_csv.png",
  "link": "PdmVJcQC6FMC6Knj6NB3ME",
  "price": "Free",
  "cover": "excel_to_csv_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Bitcoin Price From CoinMarketCap",
  "created_at": "2022-11-18T20:37:35",
  "updated_at": "2022-11-18T20:37:35",
  "level": 2,
  "description": "This template shows how to get the Bitcoin price data from CoinMarketCap website.",
  "slug": "extract-bitcoin-price-from-coinmarketcap",
  "category": "Crypto",
  "featured": false,
  "applications": [
   "Chrome",
   "Javascript"
  ],
  "tags": [
   "crypto",
   "video"
  ],
  "icon": "coinmarketcap_icon.png",
  "link": "LbkeUEmSp1F1x726u8KBVn",
  "price": "Free",
  "cover": "coinmarketcap_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Image Labels using Google Vision",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Extracts an image's first 10 labels",
  "slug": "extract-image-labels",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Google_Vision"
  ],
  "tags": [
   "image",
   "ai",
   "google"
  ],
  "icon": "extract_image_labels_icon.png",
  "link": "K8DS7SnMnZ73Q9CJJEddf5",
  "price": "Free",
  "cover": "extract_image_labels_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Text from Image Using Google Vision",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Extracts text from an image file and writes to a text file",
  "slug": "extract-text-from-image",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Google_Vision"
  ],
  "tags": [
   "image",
   "ai",
   "google",
   "ocr"
  ],
  "icon": "extract_text_from_image_icon.png",
  "link": "4rTxWStAGZ1a8MLn7yPRVe",
  "price": "Free",
  "cover": "extract_text_from_image_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Get Highlights from Instagram",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Lists Instagram highlights of multiple accounts and saves them to specified folder",
  "slug": "get-instagram-highlights",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram"
  ],
  "tags": [
   "instagram",
   "social media",
   "highlight"
  ],
  "icon": "get_instagram_highlights_icon.png",
  "link": "KmNdNBXCohvRoVHFCSuCqX",
  "price": "Free",
  "cover": "get_instagram_highlights_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Get Stories from Instagram",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Lists Instagram stories of multiple accounts and saves them to specified folder",
  "slug": "get-instagram-stories",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram"
  ],
  "tags": [
   "instagram",
   "social media",
   "story"
  ],
  "icon": "get_instagram_stories_icon.png",
  "link": "Q5AKNp89bFcB9GaCU9Ui5v",
  "price": "Free",
  "cover": "get_instagram_stories_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Get Tweet Count",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Returns the tweet count of specified account",
  "slug": "get-tweet-count",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "social media",
   "twitter"
  ],
  "icon": "get_tweet_count_icon.png",
  "link": "B2JMTRm5bBFJwCCDhboSAm",
  "price": "Free",
  "cover": "get_tweet_count_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Export Contributors from Github",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 2,
  "description": "Exports Pinterest profile info and saves it to an excel file.",
  "slug": "github-export-contributors",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "GitHub",
   "Javascript"
  ],
  "tags": [
   "github",
   "contributor",
   "export"
  ],
  "icon": "github_icon.png",
  "link": "EaPLcqC7VG3tFpYVngKWhy",
  "price": "Free",
  "cover": "github_contributors_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export Profile Data from Github",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 2,
  "description": "Collects GitHub Profile data and saves it to an excel file.",
  "slug": "github-export-profile",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "GitHub",
   "Javascript"
  ],
  "tags": [
   "github",
   "profile",
   "scrape"
  ],
  "icon": "github_icon.png",
  "link": "9WDEbFLB1QbeyGovkcvo8z",
  "price": "Free",
  "cover": "github_profile_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export Stargazers from Github Project",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 2,
  "description": "Collects GitHub Stargazers data and saves it to an excel file.",
  "slug": "github-export-stargazers",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "GitHub",
   "Javascript"
  ],
  "tags": [
   "github",
   "stargazers",
   "export"
  ],
  "icon": "github_icon.png",
  "link": "WXeWUsnjTWQzTYfHqEFwZP",
  "price": "Free",
  "cover": "github_profile_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export User Search Results from Github",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 2,
  "description": "Exports GitHub User Search result data and saves it to an excel file.",
  "slug": "github-export-user-search-results",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "GitHub",
   "Javascript"
  ],
  "tags": [
   "github",
   "user",
   "search",
   "export"
  ],
  "icon": "github_icon.png",
  "link": "2EJxmaSB4sV7eomSEMxqT1",
  "price": "Free",
  "cover": "github_user_search_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Read Newly Added Rows from Google Sheets",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Reads new rows from a spreadsheet and writes to end of a txt file.",
  "slug": "google-sheets-new-rows-reader",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Google_Sheets",
   "Javascript"
  ],
  "tags": [
   "google",
   "sheets",
   "rows"
  ],
  "icon": "google_sheets_new_rows_reader_icon.png",
  "link": "3AdD8KE9XAuK2vibfR8udT",
  "price": "Free",
  "cover": "https://www.youtube.com/watch?v=vgURGXSDNcY",
  "author": "Robomotion"
 },
 {
  "name": "Convert Google Sheets Project to Excel File",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "This template uses *Google Sheets* and *Microsoft Excel* nodes for read range from a spreadsheet and write it to excel file.",
  "slug": "google-sheets-range-to-excel",
  "category": "Productivity",
  "featured": true,
  "applications": [
   "Excel",
   "Google_Sheets"
  ],
  "tags": [
   "google",
   "sheets",
   "excel"
  ],
  "icon": "google_sheets_range_to_excel_icon.png",
  "link": "S9hBz8woiGiUTC54Ma9xAX",
  "price": "Free",
  "cover": "google_sheets_range_to_excel_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Google Storage Quick Start",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Shows some object and bucket operations in Google Storage bucket",
  "slug": "google-storage-quick-start",
  "category": "Files",
  "featured": false,
  "applications": [
   "Google_Storage",
   "Javascript"
  ],
  "tags": [
   "google",
   "storage",
   "object",
   "cloud",
   "bucket"
  ],
  "icon": "google_storage_quick_start_icon.png",
  "link": "CUiJZbt7sUjAyy32BRRosc",
  "price": "Free",
  "cover": "google_storage_quick_start_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Handle Errors",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Shows how to throw errors and handle them.",
  "slug": "handle-errors",
  "category": "Other",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "throw",
   "catch",
   "handle_erros"
  ],
  "icon": "handle_errors_icon.png",
  "link": "9HpNCU5QkFEA1xMdwvmAQr",
  "price": "Free",
  "cover": "handle_errors_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Auto-Follower on Instagram",
  "created_at": "2022-12-23T17:39:37",
  "updated_at": "2022-12-23T17:39:37",
  "level": 2,
  "description": "Automatically follows the current followers in the target instagram account as much as the given amount and writes the results to an excel file.",
  "slug": "instagram-auto-follow",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram",
   "Javascript"
  ],
  "tags": [
   "auto",
   "follow",
   "export"
  ],
  "icon": "instagram_icon.png",
  "link": "KwGrZoK3DY5EhVPKWLcXzY",
  "price": "Free",
  "cover": "instagram_auto_follow_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Followers from Instagram",
  "created_at": "2022-12-23T17:39:37",
  "updated_at": "2022-12-23T17:39:37",
  "level": 2,
  "description": "Collects the information of the followers in the instagram account and writes the results to an excel file.",
  "slug": "instagram-follower-collector",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram",
   "Javascript"
  ],
  "tags": [
   "account",
   "follower",
   "export"
  ],
  "icon": "instagram_icon.png",
  "link": "FioTZhraNm2upVAjWxvE39",
  "price": "Free",
  "cover": "instagram_follower_collector_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Followings from Instagram",
  "created_at": "2022-12-23T17:39:37",
  "updated_at": "2022-12-23T17:39:37",
  "level": 2,
  "description": "Collects the information of the followings in the instagram account and writes the results to an excel file.",
  "slug": "instagram-following-collector",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram",
   "Javascript"
  ],
  "tags": [
   "account",
   "following",
   "export"
  ],
  "icon": "instagram_icon.png",
  "link": "MisfKEZKf7x6uSVSaMDpYY",
  "price": "Free",
  "cover": "instagram_following_collector_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Hashtag Search Results from Instagram",
  "created_at": "2022-12-23T17:39:37",
  "updated_at": "2022-12-23T17:39:37",
  "level": 2,
  "description": "Collects search results of the given hashtag, then exports the results to an excel file.",
  "slug": "instagram-hashtag-search-export",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram",
   "Javascript"
  ],
  "tags": [
   "hashtag",
   "post",
   "search",
   "export"
  ],
  "icon": "instagram_icon.png",
  "link": "ArHEgKkpSHn9JTiw7bgr4h",
  "price": "Free",
  "cover": "instagram_hashtag_search_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Notifications from Instagram",
  "created_at": "2022-12-23T17:39:37",
  "updated_at": "2022-12-23T17:39:37",
  "level": 2,
  "description": "Collects the notifications of the given instagram account and writes the results to an excel file.",
  "slug": "instagram-notification-extractor",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram",
   "Javascript"
  ],
  "tags": [
   "notification",
   "account",
   "export"
  ],
  "icon": "instagram_icon.png",
  "link": "DCD2d1rU2RGCH6hecW5Ypu",
  "price": "Free",
  "cover": "instagram_notification_extractor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Post-Commenters from Instagram",
  "created_at": "2022-12-23T17:39:37",
  "updated_at": "2022-12-23T17:39:37",
  "level": 2,
  "description": "Collects commenters and relevant comment info from the given instagram post link. Writes the results to an excel file.",
  "slug": "instagram-post-commenters-export",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram",
   "Javascript"
  ],
  "tags": [
   "post",
   "commenter",
   "account",
   "export"
  ],
  "icon": "instagram_icon.png",
  "link": "EP9zXE6J7B1xLWJamRDbgY",
  "price": "Free",
  "cover": "instagram_post_commenters_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Posts of Account from Instagram",
  "created_at": "2022-12-23T17:39:37",
  "updated_at": "2022-12-23T17:39:37",
  "level": 2,
  "description": "Collects posts and it's info from the given instagram account and writes the results to an excel file.",
  "slug": "instagram-profile-post-extractor",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Instagram",
   "Javascript"
  ],
  "tags": [
   "post",
   "account",
   "export"
  ],
  "icon": "instagram_icon.png",
  "link": "DDMCUm5wc29VJKdCuEXPi1",
  "price": "Free",
  "cover": "instagram_profile_post_extractor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "JSON Beautifier",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Beautifies a given JSON string",
  "slug": "json-beautifier",
  "category": "Other",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "javascript",
   "json",
   "beautify",
   "file"
  ],
  "icon": "json_beautifier_icon.png",
  "link": "DacNffwWbfNAcPtajprt7T",
  "price": "Free",
  "cover": "json_beautifier_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "JSON Minifier",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Minimizes a given JSON object",
  "slug": "json-minifier",
  "category": "Other",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "javascript",
   "json",
   "minify",
   "file"
  ],
  "icon": "json_minifier_icon.png",
  "link": "DLS1jwFYjScriiMXoyAEpE",
  "price": "Free",
  "cover": "json_minifier_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Create an Excel File from Json",
  "created_at": "2022-11-21T16:01:13",
  "updated_at": "2022-11-21T16:01:13",
  "level": 1,
  "description": "This template Converts json objects to excel rows and adds them in a newly created excel file.",
  "slug": "json-to-excel",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Javascript",
   "Excel"
  ],
  "tags": [
   "excel",
   "json"
  ],
  "icon": "json_to_excel_icon.png",
  "link": "UouKUTf38z7DTYAPaEVtff",
  "price": "Free",
  "cover": "json_to_excel_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Get Local Time of Given City",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Takes a city name from the user and prints the local time out",
  "slug": "local-time",
  "category": "Other",
  "featured": false,
  "applications": [
   "Chrome",
   "Javascript"
  ],
  "tags": [
   "local",
   "time",
   "city"
  ],
  "icon": "local_time.png",
  "link": "8RKNY6oGbfcZ8dE7HtgRtC",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Insert Data to MySQL Triggered by Email",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "This template uses Mail and MySQL nodes to show inserting data to a MySQL table with an email triggered flow.",
  "slug": "mail-to-mysql-inserter",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "MySQL"
  ],
  "tags": [
   "mail",
   "mysql",
   "table"
  ],
  "icon": "mail_to_mysql_inserter_icon.png",
  "link": "GQCm7esWhm2mjpNdNEhHCy",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Export Post Clappers on Medium",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Scrapes Port Clappers from given Medium Post url, and exports to an Excel file.",
  "slug": "medium-export-post-clappers",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Medium"
  ],
  "tags": [
   "post",
   "export",
   "excel"
  ],
  "icon": "medium_icon.png",
  "link": "8FW5U5vMidXEj3J8sdnyJg",
  "price": "Free",
  "cover": "medium_post_clappers_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export Search Results on Medium",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Searches given search word on Medium, collects results and exports to excel in local directory.",
  "slug": "medium-export-search-result",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Medium"
  ],
  "tags": [
   "post",
   "export",
   "excel"
  ],
  "icon": "medium_icon.png",
  "link": "AzuXA45AeheCNxWivPd9BC",
  "price": "Free",
  "cover": "medium_search_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Merge CSV Files",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Merges a group of .csv files into a CSV file",
  "slug": "merge-csv",
  "category": "Files",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "csv",
   "merge",
   "file"
  ],
  "icon": "merge_csv_icon.png",
  "link": "HbEH4nvJkWe4bk3n9DrZtn",
  "price": "Free",
  "cover": "merge_csv_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "MongoDB Quick Start",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "This flow shows create database, collection and some document operations in MongoDB",
  "slug": "mongodb-quick-start",
  "category": "IT Operations",
  "featured": false,
  "applications": [
   "Javascript",
   "MongoDB"
  ],
  "tags": [
   "database",
   "javascript",
   "mongodb"
  ],
  "icon": "mongodb_quick_start_icon.png",
  "link": "JZRTKteveQsga8BxDtzqS4",
  "price": "Free",
  "cover": "mongodb_quick_start_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "MySQL Quick Start",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Receives the host, port and database name from the flow. The database is connected in this directory. Sample data is inserted and selected into the database.",
  "slug": "mysql-quick-start",
  "category": "Database",
  "featured": false,
  "applications": [
   "MySQL"
  ],
  "tags": [
   "sql",
   "mysql",
   "database"
  ],
  "icon": "mysql_quick_start_icon.png",
  "link": "5hSR3pez3isp53Y1Eakzj4",
  "price": "Free",
  "cover": "mysql_quick_start_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Convert MySQL Table To Google Sheets",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Executes query for reading data from MySQL table and writes to spreadsheet.",
  "slug": "mysql-table-to-google-sheets",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Google_Sheets",
   "MySQL"
  ],
  "tags": [
   "google",
   "sheets",
   "mysql",
   "table"
  ],
  "icon": "mysql_table_to_google_sheets_icon.png",
  "link": "RQ5nvdWDKDFY4S1voC75gZ",
  "price": "Free",
  "cover": "mysql_table_to_google_sheets_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Summarize News from CNN",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Shows how to read and summarize news from CNN.",
  "slug": "news-summarizer",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Hugging_Face"
  ],
  "tags": [
   "ai",
   "news",
   "summarize"
  ],
  "icon": "news_summarizer_icon.png",
  "link": "EsT6KUJCLb7mke2rrJhNis",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Create Images using OpenAI Dall-E",
  "created_at": "2022-12-21T16:46:59",
  "updated_at": "2022-12-21T16:46:59",
  "level": 2,
  "description": "Takes a prompt text from user, sends it to OpenAI then downloads the image.",
  "slug": "openai-image-create",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "OpenAI"
  ],
  "tags": [
   "openai",
   "ai"
  ],
  "icon": "openai_image_create_icon.png",
  "link": "QN5EpQwNBmeTsifjU79ePS",
  "price": "Free",
  "cover": "openai_image_create_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Edit Images using OpenAI Dall-E",
  "created_at": "2022-12-21T16:46:59",
  "updated_at": "2022-12-21T16:46:59",
  "level": 2,
  "description": "Takes original and mask images, sends them to OpenAI then downloads the new image.",
  "slug": "openai-image-edit",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "OpenAI"
  ],
  "tags": [
   "openai",
   "ai"
  ],
  "icon": "openai_image_edit_icon.png",
  "link": "BVu4oYzRJASg6aVXXWvYMP",
  "price": "Free",
  "cover": "openai_image_edit_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Trigger Pabbly Workfrom from Robomotion",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Triggers a Pabbly workflow from a Robomotion flow then triggers a node from Robomotion flow to start the operations",
  "slug": "pabbly-triggers",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Pabbly"
  ],
  "tags": [
   "trigger",
   "workflow",
   "flow"
  ],
  "icon": "pabbly_triggers_icon.png",
  "link": "HirtoTDoZZDHKdtP1pgFq3",
  "price": "Free",
  "cover": "pabbly_triggers_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Generate Password",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Generates a strong password with specified length",
  "slug": "password-generator",
  "category": "IT Operations",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "password",
   "security"
  ],
  "icon": "password_generator_icon.png",
  "link": "2KYNeQs2y8MMsD3P5kmTVF",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Extract Data from PDF",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Receives PDF file path from the flow. The PDF file path is extracted on text, the text results are saved \"text\" variable. This output text can show with debug node.",
  "slug": "pdf-extractor",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "pdf",
   "text"
  ],
  "icon": "pdf_extractor_icon.png",
  "link": "XyYyNk2p1iwFKVe6n7y7s8",
  "price": "Free",
  "cover": "pdf_extractor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Read PDF File with OCR",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Extracts images from a pdf file, reads images with ocr, parses text, writes to a spreadsheet and uploads the pdf file to drive",
  "slug": "pdf-reader-with-ocr",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Google_Sheets",
   "Google_Drive",
   "Other",
   "Tesseract"
  ],
  "tags": [
   "pdf",
   "text",
   "google",
   "sheets",
   "drive",
   "pdfbox",
   "ocr"
  ],
  "icon": "pdf_reader_with_ocr_icon.png",
  "link": "5emcVvfKWzFmAV3x7x58EQ",
  "price": "Free",
  "cover": "https://www.youtube.com/watch?v=vgURGXSDNcY",
  "author": "Robomotion"
 },
 {
  "name": "Collect Profile Information On Pinterest",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Searches given profile names on pinterest, collects results and exports to excel in local directory.",
  "slug": "pinterest-collect-profile-info",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Pinterest"
  ],
  "tags": [
   "image",
   "profile",
   "excel"
  ],
  "icon": "pinterest_icon.png",
  "link": "HS94m91R22F7xRKNsrxX2M",
  "price": "Free",
  "cover": "pinterest_profile_collector_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Search Results on Pinterest",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Searches given search word on pinterest, collects results and exports to excel in local directory.",
  "slug": "pinterest-extract-search-results",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Pinterest"
  ],
  "tags": [
   "search",
   "excel"
  ],
  "icon": "pinterest_icon.png",
  "link": "Vs5ZoyTsmo5pN3SkA5NYie",
  "price": "Free",
  "cover": "pinterest_search_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Process Tasks Parallely on Robomotion",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Shows how to process list of task parallely.",
  "slug": "process-tasks-parallely",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Slack",
   "Javascript"
  ],
  "tags": [
   "branching",
   "parallel_processing"
  ],
  "icon": "process_tasks_parallely_icon.png",
  "link": "KKkQ2ANjAVruJPt48eimpb",
  "price": "Free",
  "cover": "process_tasks_parallely_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Profile Info from Product Hunt",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 1,
  "description": "Product Hunt Profile Scrape template collects posts and profile information from given array of usernames and saves to excel file.",
  "slug": "product-hunt-collect-profile-info",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "Javascript",
   "Product Hunt"
  ],
  "tags": [
   "scraping",
   "template"
  ],
  "icon": "product_hunt_icon.png",
  "link": "TQcLw7B5evMRvwiGKpQ9L9",
  "price": "Free",
  "cover": "product_hunt_profile_scrape_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Upvotes from Product Hunt",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 1,
  "description": "Collects upvoted posts from the given array of usernames and saves to the excel file.",
  "slug": "product-hunt-upvotes-extractor",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "Javascript",
   "Product Hunt"
  ],
  "tags": [
   "scraping",
   "template"
  ],
  "icon": "product_hunt_icon.png",
  "link": "HwdBdzosmmzAtSARo252zW",
  "price": "Free",
  "cover": "product_hunt_upvotes_extractor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Post Answers from Quora",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 1,
  "description": "Collects all replies from given post link with profile and post details, exports to the excel file.",
  "slug": "quora-collect-answers",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "Quora",
   "Javascript"
  ],
  "tags": [
   "quora",
   "answer",
   "extract"
  ],
  "icon": "quora_icon.png",
  "link": "LZCoJ1cnrJYiYSXmKZaFLL",
  "price": "Free",
  "cover": "quora_answer_extractor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Profile Informations from Quora",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 2,
  "description": "Extracts all the profile informations from given Quora Profile Link, and saves to an excel file.",
  "slug": "quora-collect-profile-information",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "Quora",
   "Javascript"
  ],
  "tags": [
   "quora",
   "profile",
   "scrape"
  ],
  "icon": "quora_icon.png",
  "link": "LvK9dRonxSGKCQVFtQbskk",
  "price": "Free",
  "cover": "quora_profile_scraper_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export From Search Result On Quora",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Receives search word from the flow. The search word is searched on Quora, the Quora results are saved in the excel file.",
  "slug": "quora-export-search-result",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Quora"
  ],
  "tags": [
   "extract",
   "search",
   "excel"
  ],
  "icon": "quora_icon.png",
  "link": "CVRHk3pnMPVSB2cE71qvLx",
  "price": "Free",
  "cover": "quora_search_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export followers from profile On Quora",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Receives URL address from the flow. The URL address is searched on Quora, the profile follower results are saved in the excel file.",
  "slug": "quora-extract-followers-from-profile",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Quora"
  ],
  "tags": [
   "extract",
   "search",
   "excel"
  ],
  "icon": "quora_icon.png",
  "link": "KW6bLbGdAce7aGne6jucWP",
  "price": "Free",
  "cover": "quora_follower_collector_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract related questions from Quora Posts",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Receives question URL address from the flow. The URL address is searched on Quora, the Quora results are saved in the excel file.",
  "slug": "quora-extract-related-questions-from-posts",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Quora"
  ],
  "tags": [
   "extract",
   "search",
   "excel"
  ],
  "icon": "quora_icon.png",
  "link": "CDSvNubMwk1kHfXhe7Qu3k",
  "price": "Free",
  "cover": "quora_related_questions_extractor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Read From Clipboard",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Reads the clipboard content, and shows it in message box.",
  "slug": "read-from-clipboard",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "clipboard",
   "paste",
   "system"
  ],
  "icon": "read_from_clipboard_icon.png",
  "link": "Gc9cprC2yHcZhAfirqZRf1",
  "price": "Free",
  "cover": "read_from_clipboard_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Read Data Text File",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Reads the content of a text file in your file system",
  "slug": "read-text-file",
  "category": "Files",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "text",
   "read",
   "file"
  ],
  "icon": "read_text_file.png",
  "link": "fy8voFJbbZ3DmvRZA8eSq",
  "price": "Free",
  "cover": "read_text_file_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Read Word Document",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Reads the content of a Word document",
  "slug": "read-word-document",
  "category": "Files",
  "featured": false,
  "applications": [
   "Word",
   "Javascript"
  ],
  "tags": [
   "microsoft",
   "word",
   "document"
  ],
  "icon": "read_word_document_icon.png",
  "link": "LrzUwmZaBagrtmsgRDKij7",
  "price": "Free",
  "cover": "read_word_document_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Solve Google ReCaptcha V2 automatically",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Verify Google ReCaptcha V2 automatically with solving services.",
  "slug": "recaptcha-solver",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "captcha"
  ],
  "icon": "recaptcha_solver_icon.png",
  "link": "H2sBgT3b7gArD7HkDvKQhr",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Collect Post Informations On Reddit",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Receives Reddit URL address from excel file. Go to the URL address on Reddit. The found Reddit posts saves in the excel file.",
  "slug": "reddit-extract-post-information",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Reddit"
  ],
  "tags": [
   "post",
   "excel",
   "extract"
  ],
  "icon": "reddit_icon.png",
  "link": "VczUjXaqyUJjjduY83G5wP",
  "price": "Free",
  "cover": "reddit_post_collector_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Replace Words in Word Document",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Replaces words in a word document",
  "slug": "replace-words-in-word-document",
  "category": "Files",
  "featured": false,
  "applications": [
   "Word",
   "Javascript"
  ],
  "tags": [
   "microsoft",
   "word",
   "document"
  ],
  "icon": "replace_words_in_word_document_icon.png",
  "link": "SiV8L16hdWvaF9ogJrttfB",
  "price": "Free",
  "cover": "replace_words_in_word_document_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Create Simple POST \u0026 GET REST Services",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Basic rest service handling example.",
  "slug": "rest-api",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "monitoring"
  ],
  "icon": "rest_api_icon.png",
  "link": "BjfyisXBwHkQ7NpHnKs5U9",
  "price": "Free",
  "cover": "rest_api_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Run Command In SSH Server",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Executes commands in ssh server",
  "slug": "run-command-in-ssh-server",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "ssh",
   "remote",
   "run_command"
  ],
  "icon": "run_command_in_ssh_server_icon.png",
  "link": "CQJ2M71v4xMnF17b7kiScY",
  "price": "Free",
  "cover": "run_command_in_ssh_server_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Run Process on Desktop",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Runs an executable file, and shows the standart output of the process in message box.",
  "slug": "run-process",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "process",
   "executable_file",
   "exe",
   "system"
  ],
  "icon": "run_process_icon.png",
  "link": "VxhM4eWXBfugY6MFsXyqSY",
  "price": "Free",
  "cover": "run_process_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Scrape Data from Page into Excel",
  "created_at": "2023-01-21T19:38:13",
  "updated_at": "2023-01-21T19:38:13",
  "level": 3,
  "description": "This template scrapes a list of items from the given URL into an Excel file.",
  "slug": "scrape-data-from-page-into-excel",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "Javascript",
   "Excel"
  ],
  "tags": [
   "excel",
   "json"
  ],
  "icon": "excel.png",
  "link": "6rAPNoKxM9ApMBrsA2MWUM",
  "price": "Free",
  "cover": "scrape_data_from_page_into_excel_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Scrape Table from Page into Excel",
  "created_at": "2023-01-21T19:38:13",
  "updated_at": "2023-01-21T19:38:13",
  "level": 2,
  "description": "This template scrapes an HTML table from a web page into an Excel file.",
  "slug": "scrape-table-from-page-into-excel",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "Javascript",
   "Excel"
  ],
  "tags": [
   "excel",
   "json"
  ],
  "icon": "excel.png",
  "link": "1c2nUCA83p5G6UGKapzcHW",
  "price": "Free",
  "cover": "scrape_table_from_page_into_excel_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Scrape Table from URL into Excel",
  "created_at": "2023-01-21T19:38:13",
  "updated_at": "2023-01-21T19:38:13",
  "level": 1,
  "description": "This templates scrapes an HTML table from the given URL into an Excel file.",
  "slug": "scrape-table-from-url-into-excel",
  "category": "Scraping",
  "featured": false,
  "applications": [
   "Excel"
  ],
  "tags": [
   "excel",
   "json"
  ],
  "icon": "excel.png",
  "link": "TQGnpz9icV8VCejxb54s2E",
  "price": "Free",
  "cover": "scrape_table_from_url_into_excel_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Capture Screen on Browser",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Browser screen capture example",
  "slug": "screen-capture",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Chrome"
  ],
  "tags": [
   "scraping",
   "browser",
   "image"
  ],
  "icon": "screen_capture_icon.png",
  "link": "KfuWZPbDz7xxVP5Y2XSezH",
  "price": "Free",
  "cover": "screen_capture_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Send Advanced Message on Discord",
  "created_at": "2022-10-11T16:46:59",
  "updated_at": "2022-10-11T16:46:59",
  "level": 2,
  "description": "Send Discord messages with embed and file.",
  "slug": "send-advanced-discord-message",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Discord"
  ],
  "tags": [
   "discord",
   "bot",
   "message"
  ],
  "icon": "send_advanced_discord_message_icon.png",
  "link": "Xw5mKcmet89SuTNPym4tH7",
  "price": "Free",
  "cover": "discord_reminder_bot_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Send GET Request",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Send a Get request to the sample server. The response shows in the message box.",
  "slug": "send-get-request",
  "category": "IT Operations",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "api",
   "request",
   "rest"
  ],
  "icon": "send_get_request_icon.png",
  "link": "AGnXFw8tYLyUnNNt7YJWhy",
  "price": "Free",
  "cover": "send_get_request_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Send Mail As HTML",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Sends mail with HTML format",
  "slug": "send-mail-as-html",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Chrome",
   "Excel"
  ],
  "tags": [
   "scraping",
   "browser",
   "excel"
  ],
  "icon": "send_mail_as_html_icon.png",
  "link": "F2BN72mKmU6vVTndHj5hGy",
  "price": "Free",
  "cover": "send_mail_as_html_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Send Mail With Attachments",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Sends e-mails with multiple attachment files",
  "slug": "send-mail-with-attachments",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "mail"
  ],
  "icon": "send_mail_with_attachments_icon.png",
  "link": "2uRze5qwkoTR7eUgwz1MjT",
  "price": "Free",
  "cover": "send_mail_with_attachments_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Send Channel Messages on Microsoft Teams",
  "created_at": "2022-10-09T13:46:59",
  "updated_at": "2022-12-09T13:46:59",
  "level": 2,
  "description": "Create a channel and send messages.",
  "slug": "send-teams-message",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Microsoft_Teams"
  ],
  "tags": [
   "microsoft",
   "teams",
   "message"
  ],
  "icon": "send_teams_message_icon.png",
  "link": "WDJihpuX5gBCTrZea2ypyy",
  "price": "Free",
  "cover": "send_teams_message_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Monitor Given Service Endpoints",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Check your service's url, port liveness and SSL certificate validity",
  "slug": "service-monitor",
  "category": "Monitoring",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "monitoring",
   "ssl"
  ],
  "icon": "service_monitor_icon.png",
  "link": "TQsBbzB6WDancjDS4ji1Tb",
  "price": "Free",
  "cover": "service_monitor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Share A Post on Facebook",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 3,
  "description": "This template shares a post on Facebook using HTTP nodes.",
  "slug": "share-a-post-on-facebook",
  "category": "Social Media",
  "featured": true,
  "applications": [
   "Facebook",
   "Javascript"
  ],
  "tags": [
   "cookie",
   "http",
   "request"
  ],
  "icon": "facebook_icon.png",
  "link": "RLKoPJ2AW3EkNsCi7NVLBS",
  "price": "Free",
  "cover": "share_a_post_on_facebook_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Forward Message from Slack To Discord",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Receives message from Slack and forwards to a Discord Channel. This template actively uses Slack and Discord Packages. Requires basic api knowledge.",
  "slug": "slack-to-discord-forwarder",
  "category": "Communication",
  "featured": true,
  "applications": [
   "Discord",
   "Slack"
  ],
  "tags": [
   "bot",
   "message",
   "receive",
   "forward"
  ],
  "icon": "slack_to_discord_forwarder_icon.png",
  "link": "EuNqs3YB5faS6rpjM3WWwP",
  "price": "Free",
  "cover": "slack_to_discord_forwarder_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Split CSV File",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Splits a CSV file to a group of .csv files containing at most specified amount of rows",
  "slug": "split-csv",
  "category": "Files",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "csv",
   "split",
   "file"
  ],
  "icon": "split_csv_icon.png",
  "link": "QoyK7sYAwiw3MD32kikSxY",
  "price": "Free",
  "cover": "split_csv_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "SQLite Quick Start",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Receives the directory from the flow. The database is created in this directory. Sample data is inserted and selected into the database.",
  "slug": "sqlite-quick-start",
  "category": "Database",
  "featured": false,
  "applications": [
   "SQLite"
  ],
  "tags": [
   "sql",
   "sqlite",
   "database"
  ],
  "icon": "sqlite_quick_start_icon.png",
  "link": "6xi5AW9H66b3fH4zTZkXmG",
  "price": "Free",
  "cover": "sqlite_quick_start_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Start Process on Desktop",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Receives path and args from the flow. The process is started.",
  "slug": "start-process",
  "category": "Files",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "start",
   "process",
   "run",
   "executable"
  ],
  "icon": "start_process_icon.png",
  "link": "Tym257winLqX3NaAT3m8VH",
  "price": "Free",
  "cover": "start_process_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Create State Machine on Robomotion",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Simulates a basic Finite State Machine example.",
  "slug": "state-machine-example",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Javascript"
  ],
  "tags": [
   "state",
   "machine",
   "transition"
  ],
  "icon": "state_machine_example_icon.png",
  "link": "6pk6ovLQswUgG83KfXmBao",
  "price": "Free",
  "cover": "state_machine_example_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Track Stock Prices on Google Sheets",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Stock price scraping flow using a Google Sheets document",
  "slug": "stock-prices-with-google-sheets",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Chrome",
   "Google_Sheets"
  ],
  "tags": [
   "google",
   "sheets",
   "scraping",
   "browser"
  ],
  "icon": "stock_prices_with_google_sheets_icon.png",
  "link": "GJC539mFATmcDHUpmSDdrQ",
  "price": "Free",
  "cover": "stock_prices_with_google_sheets_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract stock price of companies",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Extras stock price of companies using an Excel file",
  "slug": "stock-prices",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Chrome",
   "Excel"
  ],
  "tags": [
   "scraping",
   "browser",
   "excel"
  ],
  "icon": "stock_prices_icon.png",
  "link": "RhFQaBvh1UbfdkVkDpLaqk",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Technical Analysis Example",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Generates technical indicators and plots them in a graph",
  "slug": "technical-analysis",
  "category": "Crypto",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "indicators",
   "analysis",
   "exchange"
  ],
  "icon": "technical_analysis_icon.jpg",
  "link": "5t7XNwqHJTB8sPNV4sKp2t",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Reply Message on Telegram",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Receives message from telegram and replies",
  "slug": "telegram-replier-bot",
  "category": "Communication",
  "featured": false,
  "applications": [
   "Telegram"
  ],
  "tags": [
   "telegram",
   "bot",
   "message"
  ],
  "icon": "telegram_replier_bot_icon.png",
  "link": "HU3xkUouYMDjf4p2hHzXR",
  "price": "Free",
  "cover": "telegram_replier_bot_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Profile Videos on Tiktok",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 2,
  "description": "Receives Tiktok profile URL address from the flow. The URL address is searched on Tiktok. The found profile information, video results save as an excel file. Requires basic API knowledge.",
  "slug": "tiktok-collect-profile-videos",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Tiktok"
  ],
  "tags": [
   "video",
   "post",
   "search",
   "profile"
  ],
  "icon": "tiktok_icon.png",
  "link": "VHttGeTiRZoyaUWKErgU2g",
  "price": "Free",
  "cover": "tiktok_profile_videos_collector_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Popular Hashtags on Tiktok",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Receives Hashtag from the flow. The hashtag is searched on TikTok. The found video results are saved as excel file. Requires basic API knowledge.",
  "slug": "tiktok-collect-videos-with-hastags",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Tiktok"
  ],
  "tags": [
   "video",
   "hashtag"
  ],
  "icon": "tiktok_icon.png",
  "link": "TGYdeQq4jqgMMAv9dLBZMv",
  "price": "Free",
  "cover": "tiktok_hashtag_collector_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export From Search Results On Tiktok",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Receives search word from the flow. The search word is searched on TikTok. The found video results are saved as excel file. Requires basic API knowledge.",
  "slug": "tiktok-extract-search-results",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Tiktok"
  ],
  "tags": [
   "video",
   "search"
  ],
  "icon": "tiktok_icon.png",
  "link": "CiAApVh9VzQZXUqDMtxj8c",
  "price": "Free",
  "cover": "tiktok_video_search_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Use Google Translate with Robomotion",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 1,
  "description": "Translates the text from a specified language to another one using Google Translate",
  "slug": "translator",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Chrome"
  ],
  "tags": [
   "browser",
   "dialog",
   "translate"
  ],
  "icon": "translator_icon.png",
  "link": "LEvBeXXQgW3eS7utZ3JNfx",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Collect Profile Informations On Twitter",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Receives Twitter username from the flow. The username is searched on Twitter. the found profile information, tweet results save as an excel file. Requires basic API knowledge.",
  "slug": "twitter-collect-profile-informations-from-twitter",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "extract",
   "profile",
   "tweet"
  ],
  "icon": "twitter_icon.png",
  "link": "7D5hh6aPa8ddkxtuzXiaVp",
  "price": "Free",
  "cover": "twitter_profile_scrape_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Tweets from Account on Twitter",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Receives account username from the flow. The username is searched on Twitter, The found username tweet results are saved on the excel file. Requires basic API knowledge.",
  "slug": "twitter-collect-tweets-from-account",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "tweet",
   "excel",
   "extract"
  ],
  "icon": "twitter_icon.png",
  "link": "QXwsSoPkF95j28n78Uod3n",
  "price": "Free",
  "cover": "twitter_tweet_extractor_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Export From Search Results On Twitter",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Receives search word from the flow. The search word is searched on Twitter. The found tweet results are saved in the excel file. Requires basic API knowledge.",
  "slug": "twitter-export-search-result",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "export",
   "tweet",
   "excel"
  ],
  "icon": "twitter_icon.png",
  "link": "16TViCRvzVFqMEeVtN6DQt",
  "price": "Free",
  "cover": "twitter_search_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Followers from Twitter Account",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 1,
  "description": "Lists the followers of a Twitter account and saves them to an excel file",
  "slug": "twitter-extract-followers-from-account",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "social media",
   "twitter"
  ],
  "icon": "twitter_icon.png",
  "link": "PmVz9bCawisqtcZNjHRTx7",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Extract Followings from Twitter Account",
  "created_at": "2022-11-18T17:57:30",
  "updated_at": "2022-11-18T17:57:30",
  "level": 0,
  "description": "Extracts the followings of a Twitter account and saves them to an excel file",
  "slug": "twitter-extract-followings-from-account",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "social media",
   "twitter"
  ],
  "icon": "twitter_icon.png",
  "link": "V7dqDhawoCTmKrj3Crpzn8",
  "price": "Free",
  "cover": "twitter_get_followings_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Tweets from Hashtags on Twitter",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 3,
  "description": "Receives Hashtag from the flow. The Hashtag is searched on Twitter, the tweet results are saved in the excel file. Requires basic API knowledge.",
  "slug": "twitter-extract-hashtag-results",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "tweet",
   "extract",
   "hashtag",
   "excel"
  ],
  "icon": "twitter_icon.png",
  "link": "R1xzTWZjmyLK6dLucpd2BW",
  "price": "Free",
  "cover": "twitter_hashtag_search_export_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Auto-Follow Account on Twitter",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 0,
  "description": "Follows a specified Twitter account",
  "slug": "twitter-follow-account",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Twitter"
  ],
  "tags": [
   "social media",
   "twitter"
  ],
  "icon": "twitter_icon.png",
  "link": "52VHFykibFUvnsAhe5t3Qt",
  "price": "Free",
  "cover": "twitter_auto_follow_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Upload File To SSH Server",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Uploads file from local computer to SSH server",
  "slug": "upload-file-to-ssh-server",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "ssh",
   "remote",
   "upload"
  ],
  "icon": "upload_file_to_ssh_server_icon.png",
  "link": "TdNu9W6RNP6xsMHYzrn5ZJ",
  "price": "Free",
  "cover": "upload_file_to_ssh_server_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Convert Web Element To Pdf",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Converts web element to Pdf",
  "slug": "web-element-to-pdf",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "html",
   "pdf"
  ],
  "icon": "web_element_to_pdf_icon.png",
  "link": "PK4qjT2Lf1P6DgyCvYSoaP",
  "price": "Free",
  "cover": "web_element_to_pdf_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Share Website Screenshot on Slack",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Takes screenshot of a web site and uploads it to specified slack channel",
  "slug": "website-ui-tracker",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Slack",
   "Chrome"
  ],
  "tags": [
   "monitoring",
   "messaging"
  ],
  "icon": "website_ui_tracker_icon.png",
  "link": "CyMSTNwxGtGysKzh6mkLiM",
  "price": "Free",
  "cover": "website_ui_tracker_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "WhatsApp FAQ Bot",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Basic Whatsapp FAQ Bot example for customer support",
  "slug": "whatsapp-faq-bot",
  "category": "Productivity",
  "featured": false,
  "applications": [
   "Javascript",
   "Twilio"
  ],
  "tags": [
   "whatsapp",
   "chatbot"
  ],
  "icon": "whatsapp_faq_bot_icon.png",
  "link": "44BFxgmAyWYEkxQXW1dFpU",
  "price": "Free",
  "cover": "whatsapp_faq_bot_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Write Data To Clipboard",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Receives an input from user, and copies it to clipboard.",
  "slug": "write-to-clipboard",
  "category": "Other",
  "featured": false,
  "applications": [
   "Other"
  ],
  "tags": [
   "clipboard",
   "copy",
   "system"
  ],
  "icon": "write_to_clipboard_icon.png",
  "link": "KSn5ZFadVLuGeKxXPzHmw4",
  "price": "Free",
  "cover": "write_to_clipboard_screenshot1.png",
  "author": "Robomotion"
 },
 {
  "name": "Read from TXT File and Write to Word Document",
  "created_at": "2022-10-08T21:01:13",
  "updated_at": "2022-10-08T21:01:13",
  "level": 0,
  "description": "Reads a txt file and writes output to a new word document",
  "slug": "write-to-word-document",
  "category": "Files",
  "featured": false,
  "applications": [
   "Word",
   "Javascript"
  ],
  "tags": [
   "microsoft",
   "word",
   "document"
  ],
  "icon": "write_to_word_document_icon.png",
  "link": "9UtTRbZ7Ns7ruEeBJW4rPU",
  "price": "Free",
  "cover": "write_to_word_document_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Collect Video Stats on Youtube",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 2,
  "description": "Extracts video stats from a Youtube video",
  "slug": "youtube-collect-video-stats",
  "category": "Social Media",
  "featured": true,
  "applications": [
   "Youtube",
   "Javascript"
  ],
  "tags": [
   "comments",
   "dislikes",
   "likes",
   "stats",
   "views",
   "video",
   "youtube"
  ],
  "icon": "youtube_video_stats_icon.png",
  "link": "2WRmDHMou3qRAYjV3fS29p",
  "price": "Free",
  "cover": "yt_video_stats_screenshot.png",
  "author": "Robomotion"
 },
 {
  "name": "Extract Channel Information from Youtube",
  "created_at": "2022-11-20T09:31:36",
  "updated_at": "2022-11-20T09:31:36",
  "level": 1,
  "description": "Extracts channel information from a Youtube channel",
  "slug": "youtube-extract-channel-information",
  "category": "Social Media",
  "featured": false,
  "applications": [
   "Chrome",
   "Javascript",
   "Youtube"
  ],
  "tags": [
   "youtube",
   "channel",
   "stats"
  ],
  "icon": "youtube_channel_info_icon.png",
  "link": "YJntPPciJ1hN6jxU8jieMH",
  "price": "Free",
  "cover": "",
  "author": "Robomotion"
 },
 {
  "name": "Extract Videos from Youtube Channel",
  "created_at": "2022-11-18T17:39:37",
  "updated_at": "2022-11-18T17:39:37",
  "level": 2,
  "description": "Youtube Channel Video Collector template collects video urls from given channels with excel file.",
  "slug": "youtube-extract-videos-from-channel",
  "category": "Social Media",
  "featured": true,
  "applications": [
   "Javascript",
   "Youtube"
  ],
  "tags": [
   "social media",
   "template",
   "http"
  ],
  "icon": "youtube_icon.png",
  "link": "31GkvZJP9EzhnBqPnD39Yc",
  "price": "Free",
  "cover": "yt_channel_video_collector_screenshot.png",
  "author": "Robomotion"
 }
];

export default templates;
