import { useEffect, useState } from 'react';
import Logo from './Logo';
import { getProfile } from '../../const/cookie';
import colors from '../../const/colors';
import Cookies from 'js-cookie';

import {
    createStyles,
    Header,
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Divider,
    Image,
    Container,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    Menu,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    IconBook,
    IconPlayerPlay,
    IconBoxModel2,
    IconCarCrash,
    IconMessageCircle2,
    IconDeviceDesktop,
    IconFileSpreadsheet,
    IconRobot,
    IconBrandGithub,
    IconBuildingStore,
    IconBuilding,
    IconUserCheck,
    IconTruckLoading,
    IconHomeDollar,
    IconAd2,
    IconArticle,
    IconAffiliate,
    IconAppWindow,
    IconBook2,
    IconElevator,
    IconTemplate,
    IconBrandDiscord,
    IconChevronDown,
    IconUserCircle,
    IconTimeline,
    IconLogout,
} from '@tabler/icons';

const onClicked = (e) => {
    const handled = [];
    if (e.target.matches('.drop-btn')) {
        let parent = document.getElementById(e.target.id).parentElement;
        while (!parent.classList.contains('drop-trigger') && parent.parentElement) {
            parent = parent.parentElement;
        }

        let dropdowns = parent.getElementsByClassName("drop-menu");
        for (let i = 0; i < dropdowns.length; i++) {
            const el = dropdowns.item(i);
            el.classList.toggle('show');
            handled.push(el);
        }

    }

    let dropdowns = document.getElementsByClassName("drop-menu");
    for (let i = 0; i < dropdowns.length; i++) {
        const el = dropdowns.item(i);
        if (el.classList.contains('show') && !handled.includes(el)) {
            el.classList.remove('show');
        }
    }

    return;

}

const onLogout = (e) => {

    const url = "https://api.robomotion.io";

    const requestOptions = {
        method: 'POST',
        credentials: 'include',
       headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
    };

   fetch(`${url}/v1/auth.logout`, requestOptions)
    .then(resp => {
        try {
            Cookies.remove('profile', {"domain":"robomotion.io"});
            Cookies.remove('sid', {"domain":"robomotion.io"});
            window.location.href = '/';
        } catch (e) {}
    }).catch(err => {
        console.error(err);
   });

}

const useStyles = createStyles((theme) => ({

    header: {
        backgroundColor: colors.SecondaryBackground,
        marginTop: "30px",
        fontFamily: 'Inter !important',
        borderRadius: 15,
    },

    button: {
        backgroundColor: '#FF7064 !important',

        ...theme.fn.hover({
            backgroundColor: 'white !important',
            borderColor: '#FF7064',
            color: '#2196F3',
        }),

    },

    link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: '#202020',
        fontWeight: 400,
        fontSize: "15px",

        [theme.fn.smallerThan('lg')]: {
            height: 42,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            cursor: 'pointer',
            color: '#2196F3',
        }),
    },

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        borderRadius: theme.radius.md,
        fontSize: theme.fontSizes.md,

        ...theme.fn.hover({
            backgroundColor: '#f5f0f0',
            color: '#2196F3',
        }),

        '&:active': theme.activeStyles,
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: -theme.spacing.md,
        fontSize: theme.fontSizes.md,

        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
        paddingBottom: theme.spacing.xl,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
            }`,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('lg')]: {
            display: 'none',
        },
    },

    logo: {
        marginleft: 10,
    },

    hiddenDesktop: {
        [theme.fn.largerThan('lg')]: {
            display: 'none',
        },
    },


}));

const productData = [
    {
        icon: IconTimeline,
        title: 'Roadmap',
        description: 'Upcoming product updates, votings for requests and more',
        url: 'https://feedback.robomotion.io/roadmap'
    },
    {
        icon: IconTemplate,
        title: 'Templates',
        description: 'Sample Flows to get you started!',
        url: 'https://explore.robomotion.io/'
    },
    {
        icon: IconPlayerPlay,
        title: 'Quick Start',
        description: 'Basics to get you started using Robomotion.',
        url: 'https://docs.robomotion.io/getting-started/quick-start-guide'
    },
    {
        icon: IconBook2,
        title: 'Documentation',
        description: 'All you need to know about Robomotion.',
        url: 'https://docs.robomotion.io/'
    }
];

const communityData = [
    {
        icon: IconBrandDiscord,
        title: 'Discord',
        description: 'Robomotion Discord community',
        url: 'https://community.robomotion.io/'
    },
    {
        icon: IconMessageCircle2,
        title: 'Forum',
        description: 'Robomotion QA Forum',
        url: 'https://forum.robomotion.io/'
    },
    {
        icon: IconAppWindow,
        title: 'Webinar',
        description: 'Past live webinars to learn more about Robomotion.',
        url: '/webinar'
    },
    {
        icon: IconArticle,
        title: 'Blog',
        description: 'Robomotion business blog articles',
        url: '/blog'
    },
    {
        icon: IconBrandGithub,
        title: 'Github',
        description: 'Robomotion Github repositories',
        url: 'https://github.com/robomotionio'
    }
];

const partnersdata = [
    {
        icon: IconBuilding,
        title: 'Enterprise Partner',
        description: 'Discover Enterprise Partners or apply to join them and unlock competitive pricing, empowering you to develop powerful automation solutions tailored to your Enterprise customers.',
        url: '/partners/enterprise-partner'
    },
    {
        icon: IconAffiliate,
        title: 'Affiliate Partner',
        description: 'Become an Affiliate Partner with Robomotion RPA and start earning a competitive commission of 40% while promoting a powerful automation solution.',
        url: '/partners/affiliate-partner'
    },
    {
        icon: IconUserCheck,
        title: 'Solution Experts',
        description: 'Connect with or become a skilled Robomotion Solution Expert who can assist you in creating automation flows.',
        url: '/partners/solution-experts'
    }
];

const solutionsByIndustry = [
    {
        icon: IconHomeDollar,
        title: 'Accounting and Finance',
        description: 'Streamline financial processes and automate repetitive tasks with Robomotion.',
        url: '/solutions/accounting-and-finance-robotic-process-automation'
    },
    {
        icon: IconElevator,
        title: 'Customer Service',
        description: 'Enhance customer service efficiency and response time using Robomotion automation.',
        url: '/solutions/customer-services'
    },
    {
        icon: IconCarCrash,
        title: 'Insurance',
        description: 'Automate insurance-related workflows and claims processing with Robomotion.',
        url: '/solutions/insurance'
    },
    {
        icon: IconTruckLoading,
        title: 'Logistics',
        description: 'Optimize logistics operations and streamline supply chain management through Robomotion automation.',
        url: '/solutions/logistics'
    },
    {
        icon: IconAd2,
        title: 'Marketing',
        description: 'Automate marketing tasks and campaigns to improve efficiency and drive better results with Robomotion.',
        url: '/solutions/marketing'
    },
    {
        icon: IconBuildingStore,
        title: 'Retail',
        description: 'Automate retail operations and streamline processes to enhance productivity with Robomotion.',
        url: '/solutions/retail'
    }
];

const solutionsByUseCase = [
    {
        icon: IconBook,
        title: 'CRM Automation',
        description: 'Automate CRM processes and enhance customer relationship management using Robomotion.',
        url: '/solutions/use-case/crm-automation'
    },
    {
        icon: IconDeviceDesktop,
        title: 'Desktop Automation',
        description: 'Automate desktop applications and streamline repetitive tasks with Robomotion.',
        url: '/solutions/use-case/desktop-automation'
    },
    {
        icon: IconArticle,
        title: 'Document Automation',
        description: 'Automate document processing and streamline workflows with Robomotion.',
        url: '/solutions/use-case/document-automation'
    },
    {
        icon: IconFileSpreadsheet,
        title: 'Excel Automation',
        description: 'Automate Excel tasks and data manipulation for increased efficiency using Robomotion.',
        url: '/solutions/use-case/excel-automation'
    },
    {
        icon: IconBoxModel2,
        title: 'SAP Automation',
        description: 'Automate SAP processes and streamline enterprise resource planning with Robomotion.',
        url: '/solutions/use-case/sap-automation'
    },
    {
        icon: IconAppWindow,
        title: 'Web Automation',
        description: 'Automate web-based tasks and streamline online processes using Robomotion.',
        url: '/solutions/use-case/web-automation'
    }
];


const HeaderView = () => {
    const initialState = () => ({
        loading: true,
        profile: getProfile(),
    });

    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [productLinksOpened, { toggle: toggleProductLinks }] = useDisclosure(false);
    const [communityLinksOpened, { toggle: toggleCommunityLinks }] = useDisclosure(false);
    const [solutionLinksOpened, { toggle: toggleSolutionLinks }] = useDisclosure(false);
    const [partnerLinksOpened, { toggle: togglePartnerLinks }] = useDisclosure(false);
    const { classes, theme } = useStyles();

    const [state, setState] = useState(initialState);

    useEffect(() => {
        window.document.removeEventListener('click', onClicked);
        window.document.addEventListener('click', onClicked);
    }, [state]);

    const createLinks = (x) => {
        return x.map((item) => (
            <UnstyledButton onClick={() => { window.location.href = item.url }} className={classes.subLink} key={item.title}>
                <Group noWrap align="flex-start">
                    <ThemeIcon size={34} variant="default" radius="md">
                        <item.icon size={22} />
                    </ThemeIcon>
                    <div>
                        <Text size="sm" weight={500}>
                            {item.title}
                        </Text>
                        <Text size="xs" color="dimmed">
                            {item.description}
                        </Text>
                    </div>
                </Group>
            </UnstyledButton>
        ));
    }

    const userArea = (
        <Menu
            position="bottom-end"
            transition="pop-top-right"
        >
            <Menu.Target>
                <UnstyledButton
                >
                    <Group spacing={7}>
                        <IconUserCircle stroke={1.4} fill={theme.colors.gray[0]}
                            style={{ stroke: theme.colors.gray[8] }} />
                        <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                            {state.profile?.name}
                        </Text>
                        <IconChevronDown size={12} stroke={1.5} />
                    </Group>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item color="red" icon={<IconLogout size={18} stroke={1.5} />}>
                    <UnstyledButton onClick={onLogout}>
                        <Center inline>
                            <Text component="span" color="red" size={"sm"}>
                                Logout
                            </Text>
                        </Center>
                    </UnstyledButton>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );

    const productLinks = createLinks(productData)
    const communityLinks = createLinks(communityData)
    const partnersLinks = createLinks(partnersdata)
    const solutionsByIndustryLinks = createLinks(solutionsByIndustry)
    const solutionsByUseCaseLinks = createLinks(solutionsByUseCase)

    return (
        <Container size="xl">
            <Box pb={30}>
                <Header p="xl" py="sm"  className={classes.header}>
                    <Group position="apart" sx={{ height: '100%' }} pl="sm">
                        <Logo />
                        <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
                            <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                                <HoverCard.Target>
                                    <span className={classes.link}>
                                        <Center inline>
                                            <Box component="span" mr={5}>
                                                Product
                                            </Box>
                                            <IconChevronDown size={16}/>
                                        </Center>
                                    </span>
                                </HoverCard.Target>

                                <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                                    <Group position="apart" px="md">
                                        <Text weight={500}>Product</Text>
                                    </Group>

                                    <Divider
                                        my="sm"
                                        mx="-md"
                                        color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                    />

                                    <SimpleGrid cols={2} spacing={0}>
                                        {productLinks}
                                    </SimpleGrid>

                                    <div className={classes.dropdownFooter}>
                                        <Group position="apart">
                                            <div>
                                                <Text weight={500} size="sm">
                                                    Get started
                                                </Text>
                                                <Text size="xs" color="dimmed">
                                                    Start using Robomotion for free.
                                                </Text>
                                            </div>
                                            <Button  onClick={() => {window.location.href = "https://app.robomotion.io/create"}} variant="default">Get started</Button>
                                        </Group>
                                    </div>
                                </HoverCard.Dropdown>
                            </HoverCard>

                            <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                                <HoverCard.Target>
                                    <span className={classes.link}>
                                        <Center inline>
                                            <Box component="span" mr={5}>
                                                Community
                                            </Box>
                                            <IconChevronDown size={16}/>
                                        </Center>
                                    </span>
                                </HoverCard.Target>
                                <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                                    <Group position="apart" px="md">
                                        <Text weight={500}>Community</Text>
                                    </Group>
                                    <Divider
                                        my="sm"
                                        mx="-md"
                                        color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                    />
                                    <SimpleGrid cols={2} spacing={0}>
                                        {communityLinks}
                                    </SimpleGrid>
                                </HoverCard.Dropdown>
                            </HoverCard>
                            <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                                <HoverCard.Target>
                                    <span className={classes.link}>
                                        <Center inline>
                                            <Box component="span" mr={5}>
                                                Solutions
                                            </Box>
                                            <IconChevronDown size={16}/>
                                        </Center>
                                    </span>
                                </HoverCard.Target>

                                <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                                    <Group position="apart" px="md">
                                        <Text weight={500}>Solutions By Industry</Text>
                                    </Group>

                                    <Divider
                                        my="sm"
                                        mx="-md"
                                        color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                    />

                                    <SimpleGrid cols={2} spacing={0}>
                                        {solutionsByIndustryLinks}
                                    </SimpleGrid>

                                    <Divider
                                        my="sm"
                                        mx="-md"
                                        color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                    />
                                    <Group position="apart" px="md">
                                        <Text weight={500}>Solutions By Use Case</Text>
                                    </Group>

                                    <Divider
                                        my="sm"
                                        mx="-md"
                                        color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                    />

                                    <SimpleGrid cols={2} spacing={0}>
                                        {solutionsByUseCaseLinks}
                                    </SimpleGrid>

                                </HoverCard.Dropdown>
                            </HoverCard>
                            <a href="/downloads" className={classes.link}>
                                Download
                            </a>
                            <a href="/pricing" className={classes.link}>
                                Pricing
                            </a>
                            <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                                <HoverCard.Target>
                                    <span className={classes.link}>
                                        <Center inline>
                                            <Box component="span" mr={5}>
                                                Partners
                                            </Box>
                                            <IconChevronDown size={16}  />
                                        </Center>
                                    </span>
                                </HoverCard.Target>
                                <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                                    <Group position="apart" px="md">
                                        <Text weight={500}>Partners</Text>
                                    </Group>
                                    <Divider
                                        my="sm"
                                        mx="-md"
                                        color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                    />
                                    <SimpleGrid cols={2} spacing={0}>
                                        {partnersLinks}
                                    </SimpleGrid>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        </Group>

                        {Boolean(state.profile) ?
                            <Box className={classes.hiddenMobile}>{userArea}</Box>
                            :
                            <Group className={classes.hiddenMobile}>
                                <Button onClick={() => {window.location.href = "https://app.robomotion.io/"}} variant="outline" radius="md" color="dark" size="sm">Log in</Button>
                                <Button onClick={() => {window.location.href = "https://app.robomotion.io/create"}} variant="gradient" radius="md" gradient={{ from: colors.Primary, to: colors.Primary }}>Get Started</Button>
                            </Group>
                        }

                        <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
                    </Group>
                </Header>

                <Drawer
                    opened={drawerOpened}
                    onClose={closeDrawer}
                    size="100%"
                    padding="md"
                    title="Navigation"
                    className={classes.hiddenDesktop}
                    zIndex={1000000}
                >
                    <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx="-md">
                        <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                        <UnstyledButton className={classes.link} onClick={toggleProductLinks}>
                            <Center inline>
                                <Box component="span" mr={5}>
                                    Products
                                </Box>
                                <IconChevronDown size={16}/>
                            </Center>
                        </UnstyledButton>
                        <Collapse in={productLinksOpened}>{productLinks}</Collapse>

                        <UnstyledButton className={classes.link} onClick={toggleCommunityLinks}>
                            <Center inline>
                                <Box component="span" mr={5}>
                                    Community
                                </Box>
                                <IconChevronDown size={16} />
                            </Center>
                        </UnstyledButton>
                        <Collapse in={communityLinksOpened}>{communityLinks}</Collapse>

                        <UnstyledButton className={classes.link} onClick={toggleSolutionLinks}>
                            <Center inline>
                                <Box component="span" mr={5}>
                                    Solutions
                                </Box>
                                <IconChevronDown size={16}/>
                            </Center>
                        </UnstyledButton>
                        <Collapse in={solutionLinksOpened}>{solutionsByIndustryLinks}</Collapse>
                        <Collapse in={solutionLinksOpened}>{solutionsByUseCaseLinks}</Collapse>

                        <a href="/downloads" className={classes.link}>
                            Download
                        </a>
                        <a href="/pricing" className={classes.link}>
                            Pricing
                        </a>
                        <UnstyledButton className={classes.link} onClick={togglePartnerLinks}>
                            <Center inline>
                                <Box component="span" mr={5}>
                                    Partners
                                </Box>
                                <IconChevronDown size={16} />
                            </Center>
                        </UnstyledButton>
                        <Collapse in={partnerLinksOpened}>{partnersLinks}</Collapse>

                        <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                        {Boolean(state.profile) ?
                            <Box className={classes.hiddenDesktop}>
                                <Button className={classes.link} variant="white" onClick={onLogout}>
                                    <Center inline>
                                        <IconLogout size={16} color="red" />
                                        <Text component="span" ml={5} color="red">
                                            Logout
                                        </Text>
                                    </Center>
                                </Button>
                            </Box> :
                            <Group position="center" grow pb="xl" px="md">
                                <Button variant="default">Log in</Button>
                                <Button variant="gradient" gradient={{ from: 'orange', to: 'red' }}>Start for Free</Button>
                            </Group>
                        }

                    </ScrollArea>
                </Drawer>
            </Box>
        </Container>
    );
}

export default HeaderView;