import {
    createStyles,
    Text,
    Navbar,
    UnstyledButton,
    Badge,
    Group,
    Select,
    Autocomplete,
} from '@mantine/core';
import {
    IconBulb,
    IconUser,
    IconCheckbox,
    IconSearch,
} from '@tabler/icons';
import { useState } from 'react';
import qs from 'query-string';
import colors from '../../const/colors'
import { airtable, discord, googleSheets, googleStorage, telegram, twilio, tiktok, quora, pinterest, github, medium, reddit, productHunt } from '../../const/icons';

const iconMapping = {
    "communication": "mdi-message",
    "files": "mdi-file",
    "it operations": "mdi-laptop",
    "monitoring": "mdi-monitor-dashboard",
    "productivity": "mdi-factory",
    "quora": quora(),
    "github": github(),
    "productHunt": productHunt(),
    "medium": medium(),
    "reddit": reddit(),
    "pinterest": pinterest(),
    "samples": "mdi-factory",
    "scraping": "mdi-web",
    "social media": "mdi-pound",
    "airtable": airtable(),
    "dropbox": "mdi-dropbox",
    "instagram": "mdi-instagram",
    "amazon_s3": "mdi-aws",
    "chrome": "mdi-google-chrome",
    "excel": "mdi-microsoft-excel",
    "google_drive": "mdi-google-drive",
    "google_sheets": googleSheets(),
    "google_storage": googleStorage(),
    "discord": discord(),
    "tiktok": tiktok(),
    "telegram": telegram(),
    "google_vision": "mdi-eye",
    "javascript": "mdi-language-javascript",
    "mongodb": "mdi-database",
    "mysql": "mdi-database",
    "other": "mdi-dots-horizontal",
    "twitter": "mdi-twitter",
    "slack": "mdi-slack",
    "sqlite": "mdi-database",
    "tesseract": "mdi-ocr",
    "twilio": twilio(),
    "word": "mdi-file-word",
    "microsoft_teams": "mdi-microsoft-teams"
};

const useStyles = createStyles((theme) => ({

    navbar: {
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: colors.PrimaryBackground,
        zIndex: 0,
    },

    section: {
        marginLeft: theme.spacing.md,
        marginRight: theme.spacing.md,
        marginBottom: theme.spacing.md,

        '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
                }`,
        },
    },

    searchCode: {
        fontWeight: 700,
        fontSize: 10,
        backgroundColor: colors.PrimaryBackground,
        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
            }`,
    },

    mainLinks: {
        paddingLeft: theme.spacing.md - theme.spacing.xs,
        paddingRight: theme.spacing.md - theme.spacing.xs,
        paddingBottom: theme.spacing.md,
    },

    mainLink: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        fontSize: theme.fontSizes.xs,
        padding: `8px ${theme.spacing.xs}px`,
        borderRadius: theme.radius.sm,
        fontWeight: 500,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

        '&:hover': {
            backgroundColor: colors.SecondaryBackground,
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
        '&[data-selected="true"]': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    mainLinkInner: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        fontSize: theme.fontSizes.md,
    },

    mainLinkIcon: {
        marginRight: theme.spacing.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    },

    mainLinkBadge: {
        padding: 2,
        width: 30,
        height: 20,
        pointerEvents: 'none',
    },

    collections: {
        paddingLeft: theme.spacing.md - 6,
        paddingRight: theme.spacing.md - 6,
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    collectionsSelect: {
        marginTop: theme.spacing.md,
        paddingLeft: theme.spacing.md - 6,
        paddingRight: theme.spacing.md - 6,
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    collectionsHeader: {
        paddingLeft: theme.spacing.md + 2,
        paddingRight: theme.spacing.md,
        marginBottom: 5,
    },

    gridHeader: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 20,
        fontSize: theme.fontSizes.xl,
    },

    collectionLink: {
        display: 'block',
        padding: `8px ${theme.spacing.xs}px`,
        textDecoration: 'none',
        borderRadius: theme.radius.sm,
        fontSize: theme.fontSizes.md,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        lineHeight: 1,
        fontWeight: 400,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        }
    },

    collectionLinkActive: {
        '&, &:hover': {
            color: '#325170F0',
            fontWeight: "700"
        }
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
}));

const groupBy = (arr, key) => {
    return arr.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const latestTemplates = (templates) => {
    const groups = groupBy(templates, 'created_at');
    const key = Object.keys(groups).sort().at(-1);
    return groups[key];
}

const links = [
    { icon: IconBulb, label: 'All Templates', href: 'https://explore.robomotion.io', notifications: (templates) => templates.length },
    { icon: IconCheckbox, label: 'Latest Added', href: '/latest/', notifications: (templates) => latestTemplates(templates)?.length ?? 0 },
    { icon: IconUser, label: 'Featured Templates', href: '/featured/', notifications: (templates) => templates.filter(t => t.featured).length },
];

export const goTo = (href) => {
    if (href === '/') {
        window.location.href = window.location.pathname.split('/').slice(0, -1).join('/') + '/'
        return;
    }

    if (isLinkSelected(href)) {
        return;
    }
    window.location.href = href;
}

const isLinkSelected = (href) => {
    if (href === '/' && window.location.pathname.endsWith('/')) {
        return true;
    }
    return window.location.pathname.split('/').at(-1) === href;
}


const Categories = ({ applications = [], categories = [], templates = [],
    application = '', category = '', search = '', onSearch }) => {

    const { classes, cx } = useStyles();

    const mainLinks = links.map((link) => (
        <UnstyledButton key={link.label} className={classes.mainLink} onClick={(e) => goTo(link.href)} data-selected={isLinkSelected(link.href)}>
            <div className={classes.mainLinkInner}>
                <link.icon size={22} className={classes.mainLinkIcon} stroke={1.5} />
                <span>{link.label}</span>
            </div>
            {link.notifications && (
                <Badge size="xs" radius="sm" variant="filled" className={classes.mainLinkBadge}>
                    {link.notifications(templates)}
                </Badge>
            )}
        </UnstyledButton>
    ));


    const initialState = () => {
        return {
            application,
            category,
            search,
        };
    }

    const [state, setState] = useState(initialState);

    const onSearchChanged = (search) => {
        onSearch(search);
        setState({ ...state, search });
    }

    const onSearchSubmited = (e) => {
        if (e.key !== 'Enter') return;

        window.location.href = qs.stringifyUrl(
            { url: "/", query: { "application": state.application, "category": state.category, "search": state.search } },
            { skipNull: true, skipEmptyString: true }
        );
    }

    const collectionLinks = categories.map((categorie) => {
        const lowerCat = categorie.toLowerCase();
        const slug = categorie.toLowerCase().replace(" ", "-");
        const href = `/categories/${slug}/`;

        return (
            <a
                href={window.location.pathname.startsWith(href) ? '/' : href}
                key={lowerCat}
                className={cx(classes.collectionLink, { [classes.collectionLinkActive]: category === slug })}
            >
                <span style={{ marginRight: 9, fontSize: 16 }}></span> {categorie}
            </a>
        )
    });

    return (
        <Navbar width={{ sm: 300 }} height={700} className={classes.navbar}>
            <Navbar.Section className={classes.section}>

                <Autocomplete
                    placeholder='Search'
                    mb="sm"
                    limit={5}
                    px="10px"
                    pb="sm"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                    icon={<IconSearch size={16} stroke={1.5} />}
                    value={state.search}
                    data={[]}
                    onChange={(value) => onSearchChanged(value)}
                    filter={(value, item) => [item.value, item.description].some(v => v.toLowerCase().includes(value.toLowerCase()))}
                />
            </Navbar.Section>

            <Navbar.Section className={classes.section}>
                <div className={classes.mainLinks}>{mainLinks}</div>
            </Navbar.Section>

            <Navbar.Section className={classes.section}>
                <Group className={classes.collectionsHeader} position="apart">
                    <Text size="lg" weight={500} color="dimmed">
                        Browse by Categories
                    </Text>
                </Group>
                <div className={classes.collections}>{collectionLinks}</div>
                <div className={classes.collectionsSelect}>

                    <Select
                        placeholder="Pick Category"
                        size="lg"
                        onChange={(value) => window.location.href = value.replace(" ", "-")}
                        data={categories.map(cat => {
                            const lowerCat = cat.toLowerCase();
                            return {
                                value: lowerCat,
                                label: cat,
                            };
                        })}
                    />

                </div>
            </Navbar.Section>
        </Navbar>
    );
}

export default Categories;