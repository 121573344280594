import Header from './components/shared/Header'
import Footer from './components/shared/Footer'
import Template from './components/template/Index'
import Templates from './components/templates/Index'
import Latest from './components/templates/Latest'
import Featured from './components/templates/Featured'
import Categories from './components/templates/Categories'
import Applications from './components/templates/Applications'

import React from "react";
import {
  BrowserRouter as Router,
  useRoutes
} from "react-router-dom";
import './App.css';
import { MantineProvider } from '@mantine/core'

const AppRouter = () => {
  let routes = useRoutes([
    { path: "/", element: <Templates /> },
    { path: "/templates/:slug", element: <Template /> },
    { path: "/latest", element: <Latest /> },
    { path: "/featured", element: <Featured /> },
    { path: "/categories/:slug", element: <Categories /> },
    { path: "/applications/:slug", element: <Applications /> },

    // ...
  ]);
  return routes;
};


function App() {
  return (
    <>
      <MantineProvider
        theme={{
          fontFamily: 'Barlow, sans-serif',
          fontFamilyMonospace: 'Barlow, Courier, monospace',
          headings: { fontFamily: 'Barlow, sans-serif' },
        }}
      >
      {/*<Notification />*/}
      <Header />
        <Router>
          <AppRouter />
        </Router>
      <Footer />
      </MantineProvider>
    </>
  );
}

export default App;
