import {
    createStyles,
    Title,
    Text,
    Container,
    Mark,
    Center
} from '@mantine/core';

import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 50,
        paddingBottom: 20,
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "4.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "1.8em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "850px",
        lineHeight: 1.7,
        fontFamily: 'Barlow',

    },

    subDescription: {
        marginTop: "10px",
        textAlign: 'center',
        fontSize: 20,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.Primary,
        fontFamily: 'Barlow',

    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
    highlight: {
        textDecoration: 'underlined'
    }

}));

const Hero = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                    Robomotion
                    <Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Secondary, to: colors.Secondary }}
                    >
                    {' '}Templates</Text>
                    
                </Title>
                <Container p={0} size={1100}>
                    <Center>
                        <Text className={classes.description}>
                        Ready-To-Use <Text component="span" inherit fw={600}> <Mark p={5}>Templates</Mark></Text>   to get you started with  <Text component="span" inherit fw={600}><b>Automations</b></Text> 
                        </Text>
                    </Center>
                </Container>
            </div>
        </Container>

    );
}

export default Hero;
