import { useEffect, useState } from 'react';
import config from '../../const/config';
import { getProfile } from '../../const/cookie';
import axios from 'axios';
import Faq from './Faq';
import ApplicationIcon from '../shared/Applications'

import {
    createStyles,
    Title,
    Text,
    Alert,
    Button,
    Navbar,
    Container,
    Breadcrumbs,
    Divider,
    Anchor,
    Badge,
    Group,
    ActionIcon,
    Card,
    Rating,
    Paper,
    Flex,
    ThemeIcon,
    Timeline,
    SimpleGrid,
    Tooltip,
    Center,
    Image,
} from '@mantine/core';
import {
    IconUpload,
    IconAlertCircle,
    IconEqual,
    IconSquarePlus,
    IconCircleDot,
    IconCopy,
    IconCheck
} from '@tabler/icons';
import TemplateCard from '../shared/Card';
import TemplatesData from '../templates/data';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import { useClipboard } from '@mantine/hooks';
import colors from '../../const/colors';

const TimelineText = (props) => <Text color="dimmed" size="md">{props.children}</Text>
const TimelineExtra = (props) => <Text size="sm" mt={4}>{props.children}</Text>
const Mdli = (props) => (
    <li>
        <TimelineText>{props.children}</TimelineText>
    </li>
);

const ParagraphExtra = (props) => <Text size="md" color={colors.PrimaryBackground} m={4}>{props.children}</Text>
const H1 = (props) => <Text size="36px" fontWeight="800" color={colors.PrimaryBackground} m={4}>{props.children}</Text>
const H2 = (props) => <Text size="28px" fontWeight="800" color={colors.PrimaryBackground} m={4}>{props.children}</Text>
//const ImageExtra = (props) => <img src={props.src} style={{ maxWidth: "90%", textAlign: "center", margin:"auto"}}  />
const ImageWithcard = (props) => 
<Center p={20}>
    <Image fit="contain" src={props.src} alt="Robomotion Templates
"></Image>
</Center>

const MdCode = (props) => {
    const { styles, theme } = useStyles();
    const clipboard = useClipboard({ timeout: 1000 });

    return (
        <div style={{ width: '100%' }}>
            <div style={{
                width: '100%', backgroundColor: '#3e3e42', color: '#ddd',
                padding: 8, display: 'flex', flex: 1, columnGap: 8, justifyContent: 'space-between'
            }}>
                <code style={{ whiteSpace: 'pre-wrap' }}>
                    {props.children}
                </code>
                <div>
                    <Tooltip label={clipboard.copied ? 'Copied' : 'Copy to Clipboard'}>
                        <ActionIcon mx={5} variant='transparent' onClick={() => !clipboard.copied && clipboard.copy(props.children)}>
                            <IconCopy size={"md"} color={theme.colors.gray[4]} stroke={1.5}
                                style={{ transition: 'all 300ms ease-in-out', position: 'absolute' }}
                                strokeDasharray={50} strokeDashoffset={clipboard.copied ? -50 : 0} />
                            <IconCheck size={"md"} color={theme.colors.green[4]} stroke={1.5}
                                style={{ transition: 'all 300ms ease-in-out', position: 'absolute' }}
                                strokeDasharray={50} strokeDashoffset={clipboard.copied ? 0 : -50} />
                        </ActionIcon>
                    </Tooltip>
                </div>
            </div >
        </div>
    );
}

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 120,
        zIndex: 0,


        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 60,
        paddingTop: 60,
    },

    highlight: {

    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 28,
        fontFamily: 'Barlow',
        
    },

    gridHeader: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 20,
        fontSize: theme.fontSizes.xl,
    },

    mainView: {
        justifyContent: "flex-start",
        alignContent: "flex-start",
        flexDirection: "row",
        [theme.fn.smallerThan('sm')]: {
            flexWrap: "wrap",
        },
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    navbar: {
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#f5f0f000',
    },

    tryButton: {
        width: "100%",
    },

    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    inputCard: {
        position: 'relative',
        cursor: 'pointer',
        transition: 'transform 150ms ease, box-shadow 100ms ease',
        padding: theme.spacing.xl,
        marginLeft: theme.spacing.xl,
        marginTop:  theme.spacing.xl,
        marginBottom:  theme.spacing.xl,
        backgroundColor: '#FFFFFF33',
        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.02)',
        },

    },

    stepsCard: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'transform 150ms ease, box-shadow 100ms ease',
        padding: theme.spacing.xl,
        marginLeft: theme.spacing.xl,
        backgroundColor: '#f5f0f000',
    },

    section: {
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        paddingBottom: theme.spacing.md,
    },

    like: {
        color: theme.colors.red[6],
    },

    label: {
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        fontWeight: 700,
    },
}));


const importFlow = async (template, state, setState) => {
    setState({ ...state, importing: true });
    const profile = await getProfile();
    if (!Boolean(profile)) {
        window.location.assign('https://app.robomotion.io');
        return;
    }

    const url = config.ApiURL;
    const slug = template.slug;
    const id = slug.replaceAll('-', '_');
    const req = { id };

    axios.post(`${url}/v1/templates.import`, req, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(resp => {
            const subdomain = resp.data.domain.split('.')[0];
            const appUrl = config.AppURL;
            const robomotion = appUrl.split('.').slice(1, 3).join('.');
            const flowUrl = `https://${subdomain}.${robomotion}/designer/templates/${slug}`;
            window.location.assign(flowUrl);
        })
        .catch(err => {
            window.location.assign('https://app.robomotion.io');
            return;
        });
}

function GetBadge(level) {
    if (level === 1) {
        return (
            <Badge variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Beginner</Badge>
        );
    }
    else if (level === 2) {
        return (
            <Badge variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}>Intermediate</Badge>
        );
    }
    else if (level === 3) {
        return (
            <Badge variant="gradient" gradient={{ from: 'orange', to: 'red' }}>Advanced</Badge>
        );
    }
}



function Warning(warning) {
    if (warning === undefined || warning === "" || warning === null) {
        return ""
    } else {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Alert icon={<IconAlertCircle size={16} />} title="Warning" color="yellow" radius="md">
                    <ReactMarkdown children={warning} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                </Alert>
            </>
        );
    }
}

function Note(info) {
    if (info === undefined || info === "" || info === null) {
        return ""
    } else {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Alert icon={<IconAlertCircle size={16} />} title="Info" radius="md">
                    <ReactMarkdown children={info} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                </Alert>
            </>
        );
    }
}

function Extra(extra) {
    if (extra === undefined || extra === "" || extra === null) {
        return ""
    } else if (extra instanceof Array) {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Flex direction="column" rowGap={8}>
                    {extra.map((e, i)=> (
                        <Alert key = {i} icon={<IconAlertCircle size={16} />} title="Note" radius="md">
                            <ReactMarkdown children={e} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                        </Alert>
                    ))}
                </Flex>
            </>
        );
    } else {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Alert icon={<IconAlertCircle size={16} />} title="Note" radius="md">
                    <ReactMarkdown children={extra} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                </Alert>
            </>
        );
    }
}

const getTemplate = async (state, setState, template) => {
    const url = config.ApiURL;
    const slug = template.slug;
    const id = slug.replaceAll('-', '_');

    axios.get(`${url}/v1/templates.get?id=${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        }

    }).then(resp => {
        if (resp.data.ok) {
            setState({
                ...state,
                template: Object.assign({}, {
                    ...state.template,
                    stars: resp.data.template?.stars ?? 0,
                    imports: resp.data.template?.imports ?? 0,
                }),
                star: resp.data.star,
                stars: resp.data.template?.stars ?? 0
            })
        }

    }).catch(err => {
        console.error(err);
        setState({ ...state, starInProgress: false })
    });
}

const onRate = async (e, state, setState, template) => {
    e.stopPropagation();
    e.preventDefault();

    if (state.starInProgress) {
        return;
    }

    const profile = await getProfile();
    if (!Boolean(profile)) {
        window.location.assign('https://app.robomotion.io');
        return;
    }

    setState({ ...state, starInProgress: true });

    const url = config.ApiURL;
    const slug = template.slug;
    const id = slug.replaceAll('-', '_');
    const req = { id };

    axios.post(`${url}/v1/templates.star`, req, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        }

    }).then(resp => {
        if (resp.data.ok) {
            setState({
                ...state,
                starInProgress: false,
                template: Object.assign({}, {
                    ...state.template,
                    stars: resp.data.stars ?? 0,
                    star: resp.data.star
                }),
            })
        }

        setState({ ...state, starInProgress: false });

    }).catch(err => {
        console.error(err);
        setState({ ...state, starInProgress: false })
    });
}

const Details = ({ data, content }) => {

    const { classes, theme } = useStyles();
    const category = data ? data.category.toLowerCase() : "";

    const initialState = () => {
        return {
            page: 1,
            template: data,
            templates: TemplatesData,
            importing: false,
            starInProgress: false,
        }
    };

    const [state, setState] = useState(initialState);
    const [template, setTemplate] = useState('');

    useEffect(() => {
        setTemplate(data)
    });

    var templateData = data;

    const page = state.templates.filter(t => t.Slug !== (templateData ? templateData.slug : "")).slice(0, 6);

    const bcItems = [
        { title: 'Robomotion Templates', href: '/' },
        { title: templateData ? templateData.category : "", href: `/categories/${category.replaceAll(' ', '-')}/` },
    ].map((item, index) => (
        <Anchor href={item.href} key={index}>
            {item.title}
        </Anchor>
    ));

    return (
        <Container className={classes.wrapper} size={1400}>
            <Flex className={classes.mainView}>
                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }, { maxWidth: 'lg', cols: 2 }]}>
                    <Navbar height={0} className={classes.navbar} p={"sm"}>
                        <Navbar.Section className={classes.section}>
                            <Breadcrumbs separator="→">{bcItems}</Breadcrumbs>

                            <Text size="42px" weight={700} color={colors.PrimaryText}>{templateData ? templateData.name : ""}</Text>
                            <Group position="apart" my={"sm"}>
                                <Text size="lg" weight={400} color="gray.7">{templateData ? templateData.price : ""}</Text>
                                {GetBadge(templateData ? templateData.level : "")}
                            </Group>
                            <Button size="md" my={"md"} variant="gradient" margin="auto" gradient={{ from: colors.Secondary, to: colors.Secondary }}
                                className={classes.tryButton}
                                onClick={e => importFlow(templateData, state, setState)}
                                disabled={state.importing}>
                                {state.importing ? 'Importing...' : 'Try This Template →'}</Button>

                            <Divider my="sm" variant="dotted" />
                            <Group spacing="lg" pr={"sm"}>
                                {
                                    templateData && templateData.applications && templateData.applications.map((application) => {
                                        const appIcon = ApplicationIcon(application)
                                        if (appIcon.type === "tabler") {
                                            return (
                                                <Group key={appIcon.label} my={"sm"}>
                                                    <ThemeIcon color={appIcon.color} radius={"52px"} size={"52px"} /*variant={"light"}*/ px={0} mx={0}>
                                                        <appIcon.icon size={40} stroke={1} />
                                                    </ThemeIcon>
                                                    <Text size="lg" weight={400} >{appIcon.label}</Text>
                                                </Group>
                                            )
                                        }
                                        else {
                                            return (
                                                <Group key={appIcon.label} my={"sm"}>
                                                    <ThemeIcon color={appIcon.color} radius={"52px"} size={"52px"} /*variant={"light"}*/ px={0} mx={0}>
                                                        {appIcon.icon}
                                                    </ThemeIcon>
                                                    <Text size="lg" weight={400} >{appIcon.label}</Text>
                                                </Group>
                                            );
                                        }
                                    })
                                }
                            </Group>

                            <Text size="xl" color="gray.9" py={"sm"}>
                                {templateData ? templateData.description : ""}
                            </Text>
                            <Divider my="sm" variant="dotted" />
                            <Card.Section>
                                <Group position="apart">

                                    <Group spacing={2}>
                                        <ActionIcon mx={5} onClick={(e) => onRate(e, state, setState, templateData)}>
                                            <Rating value={templateData && templateData.star ? 1 : 0} size="lg" count={1} mx={20} />
                                        </ActionIcon>
                                        <Text size="md" color="dimmed">
                                            {(templateData && templateData.stars) ?? 0}
                                        </Text>
                                        <ActionIcon mx={5}>
                                            <IconUpload color={theme.colors.gray[6]} stroke={1.5} />
                                        </ActionIcon>
                                        <Text size="md" color="dimmed">
                                            {(templateData && templateData.imports) ?? 0}
                                        </Text>
                                    </Group>

                                </Group>
                            </Card.Section>
                            <Divider my="sm" variant="dotted" />
                            <Card.Section>
                                <Group>
                                    {
                                        templateData && templateData.tags && templateData.tags.map((tag) => {
                                            return (
                                                <Group key={tag}>
                                                    <Badge color="orange">{tag}</Badge>
                                                </Group>
                                            )
                                        })
                                    }
                                </Group>
                            </Card.Section>
                            {Warning(templateData ? templateData.warning : "")}
                            {Note(templateData ? templateData.note : "")}
                            {Extra(templateData ? templateData.extra : "")}
                        </Navbar.Section>
                    </Navbar>
                    <Container p={0}>
                        <Center>
                            <Container size={800}>
                                <ReactMarkdown color={colors.PrimaryBackground} children={content} remarkPlugins={[remarkGfm]} components={{ p: ParagraphExtra, img: ImageWithcard, h1:H1, h2:H2 }} />
                            </Container>
                        </Center>
                        {
                        !templateData || !templateData.input || templateData.input.length === 0 ?
                        <></>
                        :
                        <Container p={0}>
                        <Paper radius="md" shadow="sm" className={classes.inputCard} >
                            <Group>
                                <ThemeIcon
                                    size="md"
                                    radius="md"
                                    variant="light"
                                    color="orange"
                                    gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                                >
                                    <IconSquarePlus size={20} stroke={1.5} pr={10} />
                                </ThemeIcon>
                                <Text transform="uppercase" color="dimmed" weight={700} size="xl">
                                    Inputs
                                </Text>
                            </Group>
                            {
                                templateData && templateData.input && templateData.input.length === 0 ?
                                    <Text size="lg" weight={500} mt="md">
                                        No inputs found.
                                    </Text> :
                                    templateData && templateData.input && templateData.input.map((item, i) => {
                                        return (
                                            <div key={"input" + i}>
                                                <Text size="lg" weight={500} mt="md">
                                                    {item.name}
                                                </Text>
                                                <Text size="sm" mt="5px" color="dimmed">
                                                    {item.description}
                                                </Text>
                                            </div>
                                        )
                                    })
                            }

                        </Paper>
                        </Container>
                        }
                        {
                        !templateData || !templateData.output || templateData.output.length === 0 ?
                        <></>
                        :
                        <Container p={0}>
                        <Paper shadow="sm" radius="md" className={classes.inputCard}>
                            <Group>
                                <ThemeIcon
                                    size="md"
                                    radius="md"
                                    variant="light"
                                    color="orange"
                                    gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                                >
                                    <IconEqual size={20} stroke={1.5} pr={10} />
                                </ThemeIcon>
                                <Text transform="uppercase" color="dimmed" weight={700} size="xl">
                                    Outputs
                                </Text>
                            </Group>
                            {
                                templateData && templateData.output && templateData.output.length === 0 ?
                                    <Text size="lg" weight={500} mt="md">
                                        No outputs found.
                                    </Text> :
                                    templateData && templateData.output && templateData.output.map((item, i) => {
                                        return (
                                            <div key={"output" + i}>
                                                <Text size="xl" weight={500} mt="md">
                                                    {item.name}
                                                </Text>
                                                <Text size="sm" mt="5px" color="dimmed">
                                                    {item.description}
                                                </Text>
                                            </div>
                                        )
                                    })
                            }
                        </Paper>
                        </Container>
                        }
                        {
                        !templateData || !templateData.steps || templateData.steps.length === 0 ?
                        <></>
                        :
                        <Card radius="md" className={classes.stepsCard}>
                            <Text transform="uppercase" color="orange" weight={700} size="24px">
                                Steps
                            </Text>
                            <Text color="dimmed" weight={400} size="lg" mb={"xl"}>
                                Follow these steps to test this template;
                            </Text>
                            <Timeline bulletSize={24} lineWidth={3} >
                                {
                                    templateData && templateData.steps && templateData.steps.length > 0 && templateData.steps.map((item, i) => {
                                        return (
                                            <Timeline.Item key={"step" + i} size="md"
                                                bullet={<IconCircleDot size={16} color={"white"} />} >
                                                {item.type === 'string' ?
                                                    <>
                                                        <TimelineText>{item.description}</TimelineText>
                                                        <TimelineExtra>{item.extra}</TimelineExtra>
                                                    </> :
                                                    item.type === 'md' ?
                                                        <>
                                                            <ReactMarkdown children={item.description} remarkPlugins={[remarkGfm]}
                                                                components={{ p: TimelineText, code: MdCode, li: Mdli }} />
                                                            <ReactMarkdown children={item.extra} remarkPlugins={[remarkGfm]}
                                                                components={{ p: TimelineExtra }} />
                                                        </>
                                                        :
                                                        <>
                                                            {item.description}
                                                            {item.extra}
                                                        </>
                                                }

                                            </Timeline.Item>
                                        )
                                    })
                                }
                            </Timeline>
                        </Card>
                        }
                    </Container>
                </SimpleGrid>
            </Flex>
            <Divider my="xl" variant="dotted" />
            {Faq()}
            <Divider my="xl" variant="dotted" />


            <div className={classes.hero}>
                <Container p={0} size={1100}>

                    <Title className={classes.title} pt={50}>
                        Need More <Text component="span"
                            className={classes.highlight}
                            inherit
                            variant="gradient"
                            gradient={{ from: colors.Secondary, to: colors.Secondary }}
                        >
                        {' '}<b>Templates?</b></Text>
                    </Title>

                    <Container p={5} size={1200} pb={70}>
                        <Center>
                            <Text className={classes.description}>
                                Check our similar templates
                            </Text>
                        </Center>
                    </Container>
                    <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                        {
                            page.length > 0 ?
                                page.map(t => {
                                    const description = t.description.length > 100 ?
                                        t.description.substring(0, 100) + '...' :
                                        t.description;
                                    return (
                                        <TemplateCard
                                            key={t.slug}
                                            level={t.level}
                                            applications={t ? t.applications : []}
                                            name={t.name}
                                            description={description}
                                            icon={t.icon}
                                            iconColor={t.color}
                                            slug={t.slug}
                                            star={Boolean(t.star)}
                                            stars={t.stars ?? 0}
                                            imports={t.imports ?? 0}
                                        />
                                    );
                                }) :
                                <div className="text-md font-semibold leading-normal header ml-2 my-auto mt-1">No templates found.</div>
                        }
                    </SimpleGrid>
                </Container >

            </div>
            <Divider my="xl" variant="dotted" />
        </Container >

    );
}

export default Details;