import { Container, Title, Accordion, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: theme.spacing.xl * 2,
        paddingBottom: theme.spacing.xl * 2,
    },

    title: {
        marginBottom: theme.spacing.xl * 1.5,
    },

    item: {
        borderRadius: theme.radius.md,
        marginBottom: theme.spacing.lg,

        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
    },
}));

const placeholder =
    'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.';


const faqs = [
        {
            title: 'What is Robomotion Templates?',
            description: 'Robomotion Templates are ready-to use-prebuilt flows to get you started. You can Import Templates into your workspace and run on your own computer. Just follow the given steps and try out templates.',
            value: '1'
        },
        {
            title: 'What is Automation?',
            description: 'Automation is the process of completing a task automatically. There are many types of automation, ranging from only mechanical to fully virtual and for any degree in between them. Automation is a good way to increase productivity and efficiency, but it also provides other key benefits that will help you down the road.',
            value: '2'
        },
        {
            title: 'What is Robomotion?',
            description:  'Robomotion is a cloud-native and low-code Robotic Process Automation (RPA) platform. RPA helps you to automate your manual and repetitive tasks with programmable software robots. These robots run your automations either on your machine or on the cloud. No-Code tools provide you automations with fixed inputs and outputs but with Low-Code automation products like Robomotion RPA you can create these automations. It is much more flexible for your needs.',
            value: '3'
        },
        {
            title: 'What is Robot?',
            description: 'Robots are applications that runs automations on your machine. You can install your robots to your Windows, Linux and Mac computers. For more information, please check Robomotion Documents.',
            value: '4'
        }
    ];



const Faq = () => {
    const { classes } = useStyles();

    const ListQuestions = (x) => {
        return x.map((faq) => (
            <Accordion.Item className={classes.item} value={faq.value}>
                <Accordion.Control>{faq.title}</Accordion.Control>
                <Accordion.Panel>{faq.description}</Accordion.Panel>
            </Accordion.Item>
        ));
    }

    return (
        <Container size="sm" className={classes.wrapper}>
            <Title align="center" className={classes.title}>
                Frequently Asked Questions
            </Title>

            <Accordion variant="separated">
                {ListQuestions(faqs)}
            </Accordion>
        </Container>
    );
}

export default Faq;